@import 'styles/_variables.scss';

.common {
  border-radius: 24px !important;
  height: auto !important;
  width: fit-content !important;
  line-height: 1 !important;
  max-height: 36px !important;
  span {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 16px;
  }
  svg {
    margin-right: 8px !important;
  }
  &:disabled {
    background: #ebecf0 !important;
    span {
      color: #b4b7c3 !important;
    }
    &:hover{
      background: #ebecf0 !important;
    }
  }
}
.btn {
  &--filled {
    background-color: $color-primary !important;
    border: none !important;
    padding: 10px 16px !important;
    span {
      color: $color-white;
    }
    &:hover {
      background-color: #3b61c2 !important;
    }
  }
  &--outline {
    background-color: $color-white !important ;
    padding: 9px 16px !important;
    border: 1px solid #d5d7df !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    span {
      color: #2c303c;
    }
    &:hover {
      background-color: #ebecf0 !important;
    }
    &:disabled {
      border: none !important;
    }
  }
  &--transparent {
    background-color: $color-white !important ;
    padding: 10px 16px !important;
    border: none !important;
    box-shadow: none !important ;
    span {
      color: #2c303c;
    }
    &:hover {
      background-color: #ebecf0 !important;
    }
    &:disabled {
      border: none !important;
      background-color: $color-white !important ;
      &:hover{
        background: $color-white !important;
      }
    }
  }
  &--link {
    background-color: $color-white !important ;
    padding: 10px 16px !important;
    border: none !important;
    box-shadow: none !important ;
    span {
      color: #1d7ade;
    }
    &:hover {
      background-color: $color-white !important ;
      span {
        color: #1267c4;
      }
    }
    &:disabled {
      border: none !important;
      background-color: $color-white !important ;
      &:hover{
        background: $color-white !important;
      }
    }
  }

  &--secondary {
    background-color: #E5EDFC !important ;
    padding: 10px 16px !important;
    border: none !important;
    box-shadow: none !important ;
    span {
      color: #25397C;
    }
    &:hover {
      background-color: #CEDDFB !important ;
      span {
        color: #25397C;
      }
    }
    &:disabled {
      border: none !important;
      background-color: #EBECF0 !important ;
      &:hover{
        background: $color-white !important;
      }
    }
  }
}
.icon {
  border-radius: 50% !important;
  padding: 10px !important;
  max-width: 36px;
  svg {
    margin-right: 0px !important;
    margin-top: -3px !important;
  }
}
