@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.title {
  position: relative;
  width: fit-content;
  .icon {
    position: absolute;
    right: -24px;
    top: 0px;
  }
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
  width: 100%;
  @include text-ellipsis();
}
.ellipsis {
  width: 100%;
  max-width: 150px;
  @include text-ellipsis();
}
.empty {
  text-align: center;
  margin-top: 230px;
  padding-bottom: 250px;
  height: calc(100vh - 400px);
  .content {
    margin-top: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $color-black;
  }
  .note {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $color-grey;
    margin-top: 10px;
  }
}

.rowActive td {
  background-color: $color-bg-blue !important;
  border-top: thin solid $color-info !important;
  border-bottom: thin solid $color-info !important;
}
.custom-table {
  height: 100%;
  @include custom-scroll();
}

.status {
  padding: 4px 8px;
  gap: 6px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  border-radius: 4px;
  width: fit-content;
  display: grid;
  align-items: center;

  &__approved {
    background: #d8f8e9;
    color: #01904f;
  }

  &__rejected {
    background: #fde1df;
    color: #d8382c;
  }

  &__pending {
    background: #fcf0d8;
    color: #e28800;
  }
}
