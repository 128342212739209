@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.content {
  &__identification {
    align-items: center;
    justify-content: space-between;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
    height: 36px;
    margin-bottom: 8px;
  }

  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 4px;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
    margin: 0;
  }
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.button {
  border: 0.5px solid $color-grey-light !important;
  color: $color-primary !important;
  background: $color-white;
  height: 36px;
  border-radius: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background-color: $color-white !important;
    border: 0.5px solid $color-grey-light !important;
    color: $color-primary !important;
  }

  &:focus {
    background-color: $color-white !important;
    border: 0.5px solid $color-grey-light !important;
    color: $color-primary !important;
  }
}

.disabled {
  background-color: $color-neutral-100 !important;
  border: none !important;
  color: $color-neutral-300 !important;

  &:hover {
    background-color: $color-neutral-100 !important;
    border: none !important;
    color: $color-neutral-300 !important;
    
  }
}
