@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

$bottom-heigh: 132px;
$logo-height: 68px;

.container {
  display: grid;
  grid-template-columns: 220px auto;
  min-height: 100vh;
  max-height: 100%;
}
.navbar {
  width: 100%;
  background-color: $color-primary;

  border-right: 1px solid $color-grey-light;
}
.fixed-top {
  position: fixed;
  max-width: 219px;
  width: 100%;
  top: 0px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  .menu-content {
    height: calc(100vh - $bottom-heigh - $logo-height);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.logo {
  height: $logo-height;
  box-sizing: border-box;
  padding: 24px 0 20px 24px;
}
.bottom {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  max-width: 219px;
  width: 100%;
  height: 132px;
  height: $bottom-heigh;
}

.menuContainer {
  display: grid;
  grid-row-gap: 4px;
}

.menu {
  padding-left: 24px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-top: 12px;

  &__name {
    color: #8296da;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.06em;
  }
}

.itemMenu {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  position: relative;
  padding: 12px 23px;

  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-column-gap: 12px;

  &:hover,
  &--active {
    cursor: pointer;
    width: 208px;
    height: 40px;

    border-radius: 0px 20px 20px 0px;
    position: relative;
  }

  &:hover {
    background-color: #2e479c;
  }

  &--active {
    background-color: #0f1f54;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background: #d63a95;
      border-radius: 0px 16px 16px 0px;
      width: 6px;
      height: 100%;
    }
  }

  &__name {
  }

  &__badge {
    :global {
      .ant-badge {
      }
      .ant-badge-count {
        height: 16px !important;
        box-shadow: none;
        font-weight: 600;
        font-size: 10px;
        color: #ffffff;
        background-color: $color-secondary;
        @include flex-center();
        display: flex !important;
        width: 28px;
      }
    }
  }
}

.logout {
  background-color: #182a67;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  cursor: pointer;

  &__text {
    margin-left: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #a8b8ee;
  }
}

.divLogout {
  display: flex;
}

.profile {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  &__avatar {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
    &--default {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $color-grey;
      color: $color-white;
      font-weight: 500;
      @include flex-center();
    }
  }
  &__content {
    margin-left: 12px;
    width: calc(100% - 48px);
    &--name {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $color-white;
      @include text-ellipsis;
    }
    &--role {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #8296da;
      @include text-ellipsis;
    }
  }
}

.right {
  padding: 1.5rem;
  background: $color-white-dark;
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  overflow: hidden;
}

.edit__customer {
  background: #fff !important;
  overflow-y: scroll !important;
  padding: 0 !important;
}
