@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.group {
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin: 16px;

    &__name {
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        color: $color-neutral-900;
        font-weight: 500;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white-dark;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 4px;
    }

    &__user {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey;
    }
}