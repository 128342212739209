@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.asset {
  width: 100% !important;

  :global {
    span.ant-select-selection-item-content {
      color: #111e3c;
      font-weight: 500;
      font-size: 14px;
    }

    span.ant-select-selection-item {
      border: 1px solid #eceded;
      border-radius: 20px;
      background-color: #f7f7fa;
    }

    .ant-select-selection-search {
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }

    .ant-select-selector {
      min-height: 36px;
      height: auto;
      padding: 2px 12px !important;
      border-radius: 6px !important;
    }
  }
}

.box {
  :global{
    .ant-form-item-label>label{
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #64697B;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #111e3c;
    margin-bottom: 12px;
    display: block;
  }
}
