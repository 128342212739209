.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 6px;
}

.last_update {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #62739D;
    display: flex;

    &__time {
        font-weight: 400;
        margin-left: 12px;
    }
}

.button {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    &__disable {
        cursor: default !important;
    }

    :global {
        .ant-btn {
            height: 36px !important;
        }
    }

    .confirm {
        font-weight: 500 !important;
    }
}

.modal_access_control {
    padding-top: 12px;
    max-height: 700px;
    margin: auto;

    :global {

        .ant-modal-header,
        .ant-modal-footer {
            border: none;
        }

        .ant-modal-header {
            margin-top: 12px;
        }

        .ant-modal-footer {
            padding: 0px 20px;
            margin-bottom: 28px;
        }
    }
}