@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';


.container {
    padding: 0 12px;
    padding-bottom: 0px;
    margin-top: -4px;
    height: 100%;
    position: relative;
}

.title {
    margin-top: 8px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b0b0c;
    display: flex;
    align-items: center;

    .status {
        margin-left: 16px;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        width: fit-content;
        gap: 8px;
        border-radius: 24px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
    }

    .approved {
        background: #00a55b;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .rejected {
        background: #ea4b3f;

        svg {
            width: 9px;
            height: 9px;
        }
    }
}

.customer-inform {
    margin-top: 28px;

    &__label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64697b;
    }

    &__content {
        margin-top: 6px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #2e90fa;
        cursor: pointer;
    }
}

.staking-inform {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #0c0d12;
    }
}

.content {
    background: #ffffff;
    border: 1px solid #e3e6f0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 20px;
    padding-bottom: 0px;
    margin-top: 28px;
}

.content_text {
    &__header {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 20px;
        color: $color-black !important;
    }

    &__item {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    &__item_list {
        display: flex;
        align-items: center;

        &__img {
            margin-right: 8px;
        }

        &__text {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $color-gunmetal;
        }
    }

    &__label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
        color: $color-grey;
        text-transform: initial !important;
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;
        margin: 0;

        :global {
            .ant-list-empty-text {
                text-align: start;
                padding: 0px;
            }
        }

        &__strike {
            text-decoration: line-through;
        }
    }
}