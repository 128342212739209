@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.container {
  :global {
    .ant-popover-inner-content {
      padding: 20px 16px !important;
    }

    .ant-divider-horizontal {
      margin: 16px 0;
    }

    .ant-form-item-label {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $color-black;
    }
  }

  .title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    margin: auto;
  }

  .reset {
    text-align: end;
    button {
      padding: 0;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $button-blue;
    }
  }

  .status__group {
    display: flex;
    gap: 8px;
    .status__group__item {
      border: 0.5px solid #b5b6b7;
      border-radius: 24px;
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 14px;
      color: $color-black;
    }
    :global {
      .ant-radio-button-wrapper {
        padding: 0 12px;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #2e90fa;
        background: #d1e9ff;
        border: 0.5px solid #007aff;
        box-shadow: none;
      }
    }
  }

  .multiple__select {
    :global {
      .ant-select-multiple .ant-select-selection-item {
        border: 1px solid #eceded;
        border-radius: 4px;
        gap: 4px;
        background: #f7f7fa;
      }
    }
  }

  .no__scrollbar {
    :global {
      .ant-select-selector {
        border-radius: 6px !important;
        min-height: 36px !important;
        height: auto !important;
        overflow-y: unset !important;
      }
    }
  }

  .has__scrollbar {
    :global {
      .ant-select-selector {
        border-radius: 6px !important;
        height: 36px !important;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: #909193;
          border-radius: 4px;
          width: 10px;
        }
      }
    }
  }
}
.workspace__type {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #7b7b84;
}

.disabled__button {
  pointer-events: none !important;
  span {
    color: $color-mountain-mist;
  }
}
