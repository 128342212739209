@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.comment {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;

  &__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7b7b84;
  }

  &__time {
    color: #7b7b84;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    &::before {
      content: '';
      left: -10px;
      @include abs-vertical-center();
      border-radius: 50%;
      width: 4px;
      height: 4px;
      background: #93939b;
    }
  }

  &__content {
    color: #0b0b0c;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;

    p {
      margin: 0;
    }
  }

  &__textEdited {
    position: relative;
    margin-left: 15px;

    &::before {
      content: '';
      left: -10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      width: 4px;
      height: 4px;
      background: #93939b;
    }
  }
}

.row {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 4px;

  &__top {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    align-items: center;
  }
}

.actionBtn {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  margin-top: 16px;
  float: right;
}

.actionBtnEdit {
  grid-template-columns: inherit;
  grid-template-rows: auto 1fr;
  grid-row-gap: 4px;
  margin-top: 0;
}

.action {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $color-grey;
  position: relative;

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    color: $color-black;
  }

  &--delete {
    &::before {
      content: '';
      left: -10px;
      @include abs-vertical-center();
      border-radius: 50%;
      width: 4px;
      height: 4px;
      background: #93939b;
    }
  }
}

.actionNotEditor {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 4px;
}
