.select {
  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 36px;
      height: auto;
      padding: 2px 12px;
      border-radius: 6px;
      &:hover {
        background: #f6f6f6;
        border: 1px solid #dadadb;
      }
    }
  }
}

.maxTagCount {
  :global {
    .ant-select-selection-item {
      background-color: unset !important;
      border: none !important;
    }
  }
}
