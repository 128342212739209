@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.container {
  width: 100%;
  height: 100% !important;
  height: auto;
}
.heading {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  height: 48px;
}
.next__tab {
  flex-grow: 1;
  text-align: end;
  button {
    font-size: 13px;
    padding: 0;
    gap: 9px;
    display: inline-flex;
    align-items: center;
  }
}

.heading_filter {
  margin: 24px 0;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.headingAll {
  display: block;
  height: fit-content;
}
.titleAll{
  margin: 20px 0 4px 0;
}

