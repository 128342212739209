@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
@mixin line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.tableAudit {
  :global {
    .ant-table-row {
      height: 60px;
    }
  }
}
.custom-table {
  @include custom-scroll();
}
.NormalData {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2c303c;
  @include line-clamp();
}
.date {
  font-weight: 400;
  color: #909193;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}
.titleHeaderRight {
  width: auto;
  grid-template-columns: 1fr max-content !important;
  float: right;
}
.invoiceNumber {
  font-weight: 600;
  color: $button-blue;
  font-size: 14px;
  line-height: 20px;
}
.ellipsis {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
  // max-width: 160px;
  width: calc(100% - 4px);
  @include line-clamp();
  // @include text-ellipsis();
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}
.type {
  display: flex;
  align-items: center;
  gap: 8px;
  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #909193;
    padding-top: 2px;
  }
}
.symbol {
  display: flex;
  align-items: center;
  gap: 8px;
  .icon {
    width: 20px;
    height: 20px;
    border: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
      margin-top: -5px;
    }
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    padding-top: 2px;
  }
  .symbolName {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
  }
}
.amount {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #909193;
}
.status {
  width: fit-content;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
  background-color: #b8b8c1a1;
}
.pending {
  color: $color-gold-drop;
  background: $color-papaya-whip;
}
.reviewed {
  color: $color-shamrock-green;
  background: $color-peppermint;
}
.title {
  display: flex;
  align-items: center;
  width: fit-content;
  height: auto;
  .icon {
    margin-left: 20px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #d5d7df;
    display: grid;
    align-content: center;
    justify-items: center;
  }
  .decrease {
    transform: rotate(180deg);
    margin-top: 1px;
  }
}
