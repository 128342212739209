@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.reviewing__title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
  margin-top: 8px;
  margin-bottom: 24px;
  align-items: center;
  gap: 8px;
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-grey;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.collapse {
  .user__name {
    .role {
      padding: 2px 6px;
      gap: 6px;
      height: 18px;
      border-radius: 10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      margin-left: 8px;
      margin-top: 2px;
      color: #12b76a;
      border: 1px solid #a6f4c5;
      &__viewer {
        color: $color-black;
        border: 1px solid #eceded;
      }
      &__member {
        color: #f79009;
        border: 1px solid #fedf89;
      }
    }
    .admin__icon {
      margin-top: 1px;
      margin-left: 8px;
    }
  }

  .text__portion {
    display: flex;
    flex-direction: column;

    .label_content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7b84;
    }

    .text_content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px !important;
      color: #0b0b0c;
      margin-top: 5px;
      margin-bottom: 8px;
    }
  }
}

.tableAUC {
  thead > tr > th {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    background: $color-white;
  }
  thead,
  tbody > tr > td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }
}

.user__info {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e3e6f0;
}

.total {
  .total_role_label_1 {
    font-size: 10px;
    line-height: 14px;
    color: #7b7b84;
  }

  .total_role_label_2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}

.require__approver__icon {
  margin-left: 2px;
  margin-bottom: 4px;
}
