@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.dropdown {
  padding-bottom: 0;
  :global {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      .ant-select-item-option-content {
        color: #25397c;
        font-weight: 500;
      }
      background: #e5edfc;
    }

    .ant-select-item-option {
      height: 36px;
    }
    .ant-select-selection-overflow {
      height: 28px;
    }

    .ant-select-item.ant-select-item-group {
      border-top: 1px solid #ebecf0;
      font-weight: 600;
    }
  }
}
