.dropdown {
  padding-bottom: 0;
  :global {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      .ant-select-item-option-content {
        color: #25397c;
        font-weight: 500;
      }
      background: #e5edfc;
    }

    .ant-select-item-option {
      margin: 0 4px 4px;
      border-radius: 6px;
    }
  }
}
