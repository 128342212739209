@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.table {
}
.custom-table {
  @include custom-scroll();
}

.title {
  width: fit-content;
  display: flex;
  align-items: center;
  height: auto;

  .icon {
    margin-left: 20px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #D5D7DF;
    display: grid;
    align-content: center;
    justify-items: center;
  }
  .decrease {
    transform: rotate(180deg);
    margin-top: 1px;
  }
}

.titleHeaderRight {
  width: auto;
  grid-template-columns: 1fr max-content !important;
  float: right;
}
.date {
  font-weight: 400;
  color: #909193;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}
.ellipsis {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;

  // max-width: 160px;
  width: calc(100% - 5px);
  @include text-ellipsis();
}
.id{
  white-space: nowrap;
}
.type {
  // text-align: center;
}
.symbol {
  display: flex;
  align-items: center;
  gap: 8px;
  .icon {
    width: 20px;
    height: 20px;
    border: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
      margin-top: -5px;
    }
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    padding-top: 2px;
  }

  .symbolName {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
  }
}

.amount{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .value {
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    max-width: 100px;
    min-width: 20px;
    float: right;
    @include text-ellipsis();
  }
  .usd {
    height: 20px;
    padding-top: 4px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: $color-mist-blue;
    max-width: 100px;
    min-width: 20px;
    float: right;
    @include text-ellipsis();
  }

}