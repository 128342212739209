@import 'styles/_variables.scss';
.input {
  padding: 7px 12px !important;
  background: $color-white;
  border: 1px solid #d0d4e1;
  border-radius: 6px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $color-black !important;
  :global {
    .ant-input-prefix {
      margin-right: 8px !important;
    }
    .ant-input-suffix {
      margin-left: 8px !important;
    }
  }
  &:hover {
    background: #f6f6f6 !important;
    border: 1px solid #dadadb !important;
    :global {
      .ant-input {
        background-color: #f6f6f6 !important;
        color: #64697b !important;
        outline: none !important;
      }
    }
  }
}

.read__only {
  background-color: $color-neutral-50 !important;
  border: none !important;
  color: $color-neutral-400 !important;
  &:hover {
    background-color: $color-neutral-50 !important;
    border: none !important;
  }
}
