@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
}
.body {
  position: relative;
}
.heading {
  display: flex;
  gap: 12px;
  align-items: center;
  min-height: 48px;
  height: auto;
  width: 100%;
}

.headingAll {
  display: grid;
  grid-template-rows: auto 1fr;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
}

.total {
  display: flex;
  gap: 4px;
  align-items: center;
  background: #fae1ef;
  border-radius: 24px;
  padding: 4px 8px;

  &__number {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-secondary;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
  }
  &__empty {
    color: $color-cool-grey;
    background-color: $color-dawn-pink;
  }
}

.active {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-primary;
  height: 100%;
  border-bottom: 3px solid $bd-tab;
}
.history {
  width: 100%;
  padding: 16px;
}

.filter__modal {
  :global {
    .ant-popover-inner {
      border-radius: 8px;
      border: 0.5px solid #b5b6b7;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }

    .ant-popover-inner-content {
      padding: 20px 16px !important;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.heading_filter {
  margin: 24px 0;
}

.next__tab {
  flex-grow: 1;
  text-align: end;
  button {
    font-size: 13px;
    padding: 0;
    gap: 9px;
    display: inline-flex;
    align-items: center;
  }
}

.body {
  position: relative;
  height: 100%;
}
