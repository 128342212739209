@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.popupUser {
  :global {
    .ant-modal-content {
      padding: 0;
      background-color: #ffffff;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .ant-modal-body {
      background: #ffffff;
      padding: 0;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .container {
    height: 100%;
    top: 0;
    position: relative;
  }

  .top {
    margin: 24px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0c0d12;
    }
  }

  .footer {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
  }

  .border {
    height: 1px;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;

    background: linear-gradient(
      270deg,
      #fafbff 0%,
      #ced6e8 50.95%,
      #fafbff 100%
    );
  }

  .user__modal {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 550px;

    @include custom-scroll-popup();
    padding: 24px;

    display: grid;
    grid-auto-flow: row;
  }
}

.user__modal {
  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0c0d12;
    margin: 12px 0;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7b7b84;
  }

  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $bg-line;
      border-color: $color-primary;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid $color-primary;
      border-top: 0;
      border-left: 0;
    }
    .ant-picker {
      height: 36px;
      background: $color-white;
      border-radius: 6px;
      width: 100%;
      padding: 8px 12px;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }
    .ant-form-item-label > label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #383a3c;
    }
    .ant-form-item-label {
      padding: 0 0 6px;
    }
  }

  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #383a3c;
  }

  .input {
    &__bg {
      height: 32px;
      margin-top: 8px;
      background: #f7f8fb;
      border-radius: 8px;
      padding: 6px 8px;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 8px;
      align-items: center;
      margin-bottom: 6px;
    }

    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-black;

      &--active {
        color: $color-primary;
      }
    }
  }

  .required {
    display: inline-block;
    margin-right: 4px;
    color: $color-err;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    line-height: 1;
    content: '*';
    margin-left: 3px;
  }
  .ipErr {
    :global {
      .PhoneInputInput {
        border-color: $color-err;
        position: relative;
        &:focus {
          border-color: $border-color-err;
          box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
          border-right-width: 1px;
          outline: 0;
        }
      }
    }
  }
  .code {
    position: absolute;
    left: 50px;
    top: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }

  .role__group {
    margin: 8px 0 12px 0;
    background: #f7f7fa;
    border-radius: 6px;

    &__item {
      background: #f7f7fa;
      border-radius: 6px;
      border: none;

      font-weight: 400;
      font-size: 12px;
      color: $color-black;
    }
    :global {
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: transparent !important;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: white;
        background: #25397c;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
          0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        border-radius: 4px;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: transparent !important;
      }
    }
  }

  .user__role {
    :global {
      .ant-form-item-label {
        padding: 0 !important;
      }
    }
  }

  .mutiple__select {
    :global {
      .ant-select-selector {
        border-radius: 6px !important;
        height: auto !important;
      }
      .ant-select-multiple .ant-select-selection-item {
        border: 1px solid #eceded;
        border-radius: 4px;
        gap: 4px;
        background: #f7f7fa;
      }

      .ant-select-selection-search {
        -webkit-margin-start: 0 !important;
        margin-inline-start: 0 !important;
      }
    }
  }
}
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
}
.description {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.footer {
  margin-top: 40px;
  text-align: right;
}

.selectVault {
  .vault {
    &__name {
      border-radius: 6px;
    }
  }
  :global {
    .ant-select-selector {
      border-radius: 6px !important;
      height: auto !important;
    }
    .ant-select-multiple .ant-select-selection-item {
      border: 1px solid #eceded;
      border-radius: 4px;
      gap: 4px;
      background: #f7f7fa;
    }

    .ant-select-selection-search {
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }
  }
}
