@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

:global {
  label.ant-form-item-required.ant-form-item-no-colon {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7b7b84;
  }

  .ant-picker-panel {
    width: 308px;
  }

  .ant-picker-month-panel {
    width: 100% !important;
  }
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.container {
  margin-top: 12px;
}

.info__title_month__label {
  display: inline-block !important;
  margin-left: 2px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: Poppins, sans-serif !important;
  line-height: 1 !important;
  font-weight: 400;
}

.info__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7b84;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px !important;
}

.tableAUC {
  thead > tr > th {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    background: $color-white;
  }
  thead,
  tbody > tr > td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }
}

.pendingRequestMsgWrapper {
  position: relative;
  z-index: 200;

  .innerPendingRequestMsgWrapper {
    position: absolute;
    right: 0px;
    top: 12px;
    display: flex;
    align-items: center;

    .pendingRequestMsg {
      color: $color-grey-light !important;
      font-size: 12px;
    }

    .pendingRequestButton {
      margin-left: 8px;
      padding: unset;
      font-size: 12px;
      font-weight: 600;
      height: unset;
    }
  }
}

.buttonWrapper {
  position: relative;
  width: 100%;

  .innerButtonWrapper {
    position: absolute;
    top: -46px;
    right: 0px;
    padding-left: 1em;
    background-color: $color-white;
    display: flex;
  }
}

.rangePicker {
  height: 38px;
  width: 100%;
  border-radius: 6px !important;

  &:hover {
    background-color: #f6f6f6 !important;
    border: 1px solid $color-grey-light !important;
  }
}

.suffix__input {
  font-size: 10px;
  line-height: 14px;
  color: #7b7b84;
}

.button {
  border: 0.5px solid $color-grey-light !important;
  color: $color-primary !important;
  background: $color-white;
  height: 36px;
  border-radius: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background-color: $color-white !important;
    border: 0.5px solid $color-grey-light !important;
    color: $color-primary !important;
  }

  &:focus {
    background-color: $color-white !important;
    border: 0.5px solid $color-grey-light !important;
    color: $color-primary !important;
  }
}

.button__disabled {
  color: $color-grey-light !important;

  &:hover {
    color: $color-grey-light !important;
  }

  &:focus {
    color: $color-grey-light !important;
  }
}

.confirm__popup {
  :global {
    .ant-modal-header {
      border-bottom: none;
      padding: 20px 20px 0 20px;
      .ant-modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
      }
    }
    .ant-modal-body {
      padding: 10px 20px 0px 20px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 20px;
      padding-top: 10px;
    }
    .ant-modal-close {
      display: none !important;
    }
  }

  .confirm__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }

  .confirm__button {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.production-subscription-rakkar-connect {
  flex-direction: column !important;
  .not-subscribe-text-container {
    margin: 24px 0;
    .not-subscribe-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .production-subscription-rakkar-connect-banner {
    width: 100%;
    padding: 8px;
    background: rgba(254, 240, 199, 1);

    border-radius: 4px;
    .pending-text,
    .view-request {
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .pending-text {
      color: #f79009;
      .info-icon {
        height: 20px;
        width: 20px;
        path {
          fill: #f79009;
        }
        margin-right: 8px;
      }
    }
    .view-request {
      color: #2e90fa;
    }
  }

  .subscribe-button-container {
    border: 1px solid #e4e9f6;
    border-radius: 24px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .plus-icon {
      width: 16px;
      height: 16px;
    }
    .plus-icon.disable-icon {
      path {
        fill: rgba(165, 178, 207, 1);
      }
    }
    .subscribe-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      margin-left: 8px;
    }
    .subscribe-text.disable-subscribe-text {
      color: rgba(165, 178, 207, 1) !important;
    }
  }
  .subscribe-button-container.pending {
    box-shadow: none;
    .disable-rakkar-connect-button {
      background: #f1f4fd !important;
      border: none !important;
    }
  }
}
