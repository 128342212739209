@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  max-width: 176px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.avatar {
  position: relative;

  .image {
    width: 28px;
    height: 28px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  .required {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 12px;
    height: 12px;
    background: $color-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.avatar-default {
  height: 28px;
  width: 28px;
  align-items: center;
  background-color: $color-grey;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
}

.name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
  margin-left: 12px;

  span {
    color: $color-grey;
  }
}

.name_advanced {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $color-black;
}

.user_info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.role {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $color-grey;
}