@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.empty {
  height: calc(100vh - 140px);
  @include flex-between-center();
  background-color: #ebecf0;
  border-radius: 12px;
  margin-top: 32px;
  .content {
    text-align: center;
    width: fit-content;
    margin: 0px auto;
    .text {
      margin-top: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #959aaa;
    }
    .text_appear {
      color: #959aaa;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
