@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.tooltip {
  .ant-tooltip-open {
    background-color: #0b0b0c;
    border-radius: 6px;
    padding: 12px;
  }
}

.button {
  padding: 4px 6px !important;
  gap: 8px !important;
  height: 24px !important;
  border-radius: 12px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #2e90fa !important;
  border: 1px solid #80bcff !important;
  cursor: auto !important;
  &__hot {
    color: #f04438 !important;
    border: 1px solid #fda29b !important;
  }
}
