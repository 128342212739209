@import 'styles/_variables.scss';
.container {
  height: auto;
  margin: 0px auto;
  padding: 1rem;
  :global {
    .ant-form-item-label {
      padding: 0 0 4px !important;
      line-height: 16px;
    }
    .ant-form-item {
      margin-bottom: 16px !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $bg-line;
      border-color: $color-primary;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid $color-primary;
      border-top: 0;
      border-left: 0;
    }
    label.ant-form-item-required.ant-form-item-no-colon,
    .ant-form-item-no-colon {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $color-black-grey;
    }
  }
}

.container__edit__cus {
  :global {
    label.ant-form-item-required.ant-form-item-no-colon,
    .ant-form-item-no-colon {
      color: $color-neutral-600 !important;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
  margin-bottom: 24px;
}

.sub-title {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-column-gap: 8px;
  margin-bottom: 16px;
  margin-top: 24px;
  &__content {
    color: $color-grey;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  &__edit {
    color: $color-neutral-1000 !important;
  }
  &__line {
    height: 1px;
    background-color: $bg-line;
  }
  button svg {
    width: 16px;
    height: 16px;
  }
}

.address {
  margin-bottom: 40px;
  :global {
    .ant-input-prefix {
      margin-right: 0px;
    }
    input.ant-input {
      padding-left: 8px !important;
    }
  }
  &__current,
  &__billing {
    :global {
      .ant-form-item {
        margin-bottom: 8px !important;
      }
    }
    .checkbox {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &__current {
    margin-bottom: 16px;
  }
}

.date {
  :global {
    .ant-picker {
      height: 36px;
      background: $color-white;
      border-radius: 6px;
      width: 100%;
      padding: 8px 12px;
    }
    label.ant-form-item-required {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $color-black-grey;
    }
  }
}
.phone {
  :global {
    .ant-form-item-label > label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $color-black-grey;
    }
  }
}

.required {
  display: inline-block;
  margin-right: 4px;
  color: $color-err;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  line-height: 1;
  content: '*';
  margin-left: 3px;
}

.ipErr {
  :global {
    .PhoneInputInput {
      border-color: $color-err;
      position: relative;
      &:focus {
        border-color: $border-color-err;
        box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
        border-right-width: 1px;
        outline: 0;
      }
    }
  }
}

.code {
  position: absolute;
  left: 50px;
  top: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
}

.space {
  height: 24px;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-bottom: 16px;
  background-color: $color-white-dark !important;
}

.btnAdd {
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  width: fit-content;
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-black-grey;
    display: flex;
  }

  .ant-table-cell::after {
    padding: none;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
}

.existed__popup {
  :global {
    .ant-modal-header {
      border-bottom: none;
      padding: 20px 20px 0 20px;
      .ant-modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
      }
    }
    .ant-modal-body {
      padding: 10px 20px 0px 20px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 20px;
      padding-top: 10px;
    }
    .ant-modal-close {
      display: none !important;
    }
  }

  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }
}

.button {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  :global {
    .ant-btn {
      height: 36px !important;
    }
  }
  .confirm {
    font-weight: 500 !important;
  }
}

.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.other {
  :global {
    .ant-form-item-label {
      opacity: 0;
    }
  }
}
.iconInfo {
  margin-left: 8px;
  cursor: pointer;
}
