@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  background: #ffffff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 1rem;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 12px;
}

.heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0b0b0c;
}

.list_comment {
  margin: 20px 0;
  display: grid;
  grid-row-gap: 20px;
}

.send {
  height: 44px;
  background: #eceded;
  border: 1px solid #eceded;
  border-radius: 80px;

  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 12px;
  padding: 0 8px;
  align-items: center;

  &__editor {
    background: none;
    border: none;
    height: auto;
    align-items: initial;
  }

  &__avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid $color-grey-light;
    background-color: transparent;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    /* Text/Support */

    color: #7b7b84;

    &:hover {
      cursor: pointer;
    }
  }
}

.actionBtn {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  margin-top: 16px;
  float: right;
}