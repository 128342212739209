@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.confirmModal {
  padding: 24px;
  box-sizing: border-box;
  background-color: $color-white;
  border: 1px solid #ebecf0;
  border-radius: 12px;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;

  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03) 0px 12px 16px -4px
    rgba(16, 24, 40, 0.08);

  pointer-events: auto;
  display: flex;
  flex-direction: column;
  .content-container {
    .title {
      color: #071024;
      font-size: 16;
      font-weight: 700;
      margin-bottom: 12px;
      line-height: 20px;
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      color: #7b7b84;
      line-height: 20px;
    }
    padding-bottom: 24px;
  }

  .button-container {
    padding: 24px 0 0 0;
    margin-left: auto;
    display: inline-block;

    .cancel {
      border: 1px solid rgba(228, 233, 246, 1);
      box-shadow: 0px 6px 8px 0px rgba(2, 35, 96, 0.03) 0px 0px 4px 0px
        rgba(47, 74, 161, 0.06);
    }
    .ok {
      margin-left: 16px;

      box-shadow: 0px 6px 8px 0px rgba(2, 35, 96, 0.03) 0px 0px 4px 0px
        rgba(47, 74, 161, 0.06);
    }
  }
}
