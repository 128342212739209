.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0C0D12;
    margin-left: 12px;
}

.title_container {
    display: flex;
    align-items: center;
}

.input_search {
    width: 316px;
    margin: 32px 0px;
    position: relative;
}