@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.check_all_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 16px;
    border: 1px solid #E5EDFC;
    border-radius: 4px 4px 0px 0px;

    .content {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0C0D12;
    }
}

.check_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.check_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 15px;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    :global {
        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
            cursor: default;
        }

        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: white;
        }

        .ant-checkbox-disabled .ant-checkbox-input {
            cursor: default;
        }
    }
}

.check_item_checked {

    border-radius: 4px;

    :global {
        .ant-list-item {
            background-color: #F6F6F6;
        }
    }
}

.check_box_access_control {
    &__disable {
        :global {
            .ant-checkbox-disabled {
                pointer-events: none !important;
            }
        }
    }

    :global {

        .ant-checkbox {
            background: white;
            border-radius: 4px;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $button-pink;
            border-color: $button-pink;
            border: none !important;
            border-radius: 4px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            margin-left: 1px;
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner,
        .ant-checkbox-checked:focus .ant-checkbox-inner {
            border-color: $button-pink !important;
        }
    }
}

.group_checklist {
    overflow-y: scroll;
    max-height: 400px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E4E9F6;
    border-radius: 0px 0px 8px 8px;

    &::-webkit-scrollbar {
        margin-bottom: 8px;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        transform: matrix(-1, 0, 0, 1, 0, 0);
        max-height: 50%;
        height: 50%;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        background-color: #d9d9d9;
    }
}

.list_item {
    background: none;

    &__checked {
        background: #F1F4FD;
        border-radius: 4px;
    }

    &__hide {
        display: none;
    }
}

.item_content {
    margin-left: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #0C0D12;
}

.input_search_detail {
    width: auto;
    position: relative;
    border-radius: unset;

    input {
        border-radius: 6px;
    }
}

.pending_request {
    display: flex;
    align-items: center;
    background: #FEF0C7;
    border-radius: 4px;
    padding: 9px;
    margin-bottom: 16px;

    &__content {
        margin-left: 9px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #F79009;
    }

    &__viewRequest {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #2E90FA;
        margin-left: 88px;

        &:hover {
            cursor: pointer;
        }
    }
}

.modal_content {

    :global {
        .ant-list-item {
            padding: 16px 0px;
        }

        .ant-list-split .ant-list-item {
            border: none !important;
        }

        .ant-modal-body {
            padding-top: 0px !important;
        }
    }
}