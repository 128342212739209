.title{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0B0B0C;
  }
  .content{
    margin-top: 12px;
    font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7B7B84;
  }
  .footer{
    margin-top: 44px;
    display: flex;
    justify-content: end;
    gap: 12px;
  }