@import 'styles/_variables.scss';
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.mainTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
  margin-top: 25px;
}

.info {
  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }
  .content {
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2c303c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .status {
      margin-left: 8px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      border-radius: 4px;
      color: #0b0b0c;
      background: #cfcfd1;
    }
    .approved {
      color: #01904f;
      background: #d8f8e9;
    }
    .rejected {
      color: #d8382c;
      background: #fde1df;
    }
  }
  .speech {
    color: #2c303c;
    font-weight: 600;
  }

  .reason {
    color: $color-master-error-500;
  }
}

.infoReason {
  margin-top: 16px;
}

.liveness {
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
      cursor: pointer;
    }
  }
  .images {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    margin-bottom: 20px;
    .onboarding,
    .authentication {
      max-width: 120px;
      img {
        width: 120px;
        height: 154px;
        object-fit: cover;
      }
    }
  }
}

.text {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $color-grey;
  text-align: center;
}

.passport {
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    span {
      margin-left: 12px;
      cursor: pointer;
    }
  }
  .image {
    width: 274px;
    margin-top: 12px;
    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }
}

.blur {
  img,
  video {
    filter: blur(6px);
  }
}
