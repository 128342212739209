@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
  }

  &__item {
    margin-bottom: 20px;
  }

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-neutral-500;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }

  &__old__field {
    text-decoration: line-through;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &__new__field {
    color: $color-deep-cerise;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }
}

.dot {
  background-color: #6b6d6f;
  border-radius: 50%;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 2px;
}
