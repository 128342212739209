@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.tier {
  width: fit-content;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  border-radius: 24px;
  margin-right: 6px;
}
.low__tier {
  color: $color-mist-blue;
  background: $color-dawn-pink;
}
.mid__tier {
  color: $color-primary;
  background: $color-iceberg;
}
.large__tier {
  color: $color-secondary;
  background: #fae1ef;
}

.list_VDO {
  list-style: inside;
  margin-left: 0;
  padding-left: 1.2em;
  text-indent: -1.5em;
  font-weight: normal;
  gap: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}
