@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  // overflow-y: clip;
  .customer__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0b0b0c;
  }
  .workspace {
    display: grid;
    grid-template-areas: 'workSpace workSpace workSpace moreWorkSpace';
    grid-template-columns: 1fr 1fr;
    gap: 6px;
    width: 100%;
    align-items: center;
    .workspace__text {
      grid-area: workSpace;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
    .more__workspace {
      grid-area: moreWorkSpace;
      padding: 0px 4px;
      gap: 8px;
      width: 18px;
      height: 18px;
      background: #c3c3c7;
      border-radius: 4px;
      font-size: 10px;
      white-space: nowrap;
    }
  }
  :global {
    .ant-table-column-sorter {
      display: none;
    }
  }
}
.header {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  .icon {
    height: fit-content;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b0b0c;
    margin: 0 12px;
  }
  .inactive {
    width: auto;
    padding: 4px 8px;
    background: #EBECF0;
    border-radius: 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #959AAA;
  }
  .active {
    width: auto;
    padding: 4px 8px;
    background: #e1fcea;
    border-radius: 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0a9252;
  }
}
.body {
  margin: auto;
  height: 100%;
  position: relative;
  background: $color-dawn-pink;
  border-radius: 12px;
}

.empty__state {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.title {
  position: relative;
  width: fit-content;
  height: auto;
  .icon {
    position: absolute;
    right: -20px;
    top: 0px;
  }
}

.header {
  margin-bottom: 18px;
}

.infinite__container {
  // height: 100%;
}
