@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.vault {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 30px;
    padding: 0px 16px 0px 0px;
    border: 1px solid #e3e6f0;
    max-width: fit-content;
    flex-flow: nowrap !important;

    &__icon {
        background: #f3f3f4;
        border-radius: 145.455px;
        width: 32px;
        height: 32px;
        text-align: center;

        svg {
            margin-top: 8px;
            color: $color-primary;
        }
    }

    &__name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $color-primary;
        margin: auto;
        margin-left: 8px;
        white-space: nowrap;
    }
}

.text__portion {
    padding-top: 24px;
    display: flex;
    flex-direction: column;

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #7b7b84;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px !important;
        color: #0b0b0c;
        margin-top: 5px;
        margin-bottom: 8px;
    }

    .sectionVaults {
        display: flex;
        margin-top: 8px;
        gap: 8px;
        position: relative;
    }

    .shadowVaults {
        position: absolute;
        height: 56px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        background: linear-gradient(180deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 86.61%);
    }
}