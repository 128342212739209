@import 'styles/_variables.scss';

.title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $color-cool-grey;
}

.checklist__content {
  .item__text {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    cursor: pointer;
  }

  :global {
    .ant-list-header {
      border-bottom: none !important;
      padding: 14px 32px !important;
    }
    .ant-list-item {
      border-bottom: none !important;
      padding: 20px 32px !important;
      gap: 10px !important;
      justify-content: initial;
      align-items: start;
    }

    .ant-list {
      border: none !important;
      border-radius: 0;
    }
    .ant-checkbox-group-item:hover {
      .ant-checkbox {
        background: white;
        border: none !important;
      }
    }

    .ant-checkbox {
      background: white;
      border-radius: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $button-pink;
      border-color: $button-pink;
      border: none !important;
      border-radius: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      margin: auto;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked:focus .ant-checkbox-inner {
      border-color: $button-pink !important;
    }
  }
}
