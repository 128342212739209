@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.drawerBilling{
  @include custom-scroll()
}
.id{
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $color-cool-grey;
}
.title{
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
}

.customer-info {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 8px;
  margin-top: 28px;
  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    width: 100%;
  }
  &__line {
    height: 1px;
    background-color: $color-dawn-pink;
  }
  &__name{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2C303C;
  }
}
.content {
  &__item {
    margin-top: 20px;
    width: 100%;
  }
  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2C303C;
    margin: 0;
  }
}
.wallet{
  margin-top: 20px;
  .collapse{
    display: grid;
    grid-template-rows: auto;
    gap: 20px;
    &-title{
      margin-top: 0px;
    }
    &-title__content{
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #0C0D12;
      img{
        width: 36px;
        height: 36px;
      }
      .hot-wallet{
        color: #D63A95;
      }
      .cold-wallet{
        color: #25397C;
      }
    }
  }
}
.wallet-title , .collapse-title{
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 8px;
  margin-top: 20px;
  &__content {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    width: 100%;
  }
  &__line {
    height: 1px;
    background-color: $color-dawn-pink;
  }
  .current{
    color: $button-pink;
  }
}
.history{
  margin-top: 32px;
  width: calc(100% + 48px);
  height: auto;
  background: #F7F7F9;
  padding: 16px 24px;
  border-top: 1px solid #D5D7DF;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -24px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #959AAA;
  .reviewed{
    padding: 4px 8px;
    background: #D8F8E9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #01904F;
  }
  .img{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2C303C;
  }
  .date{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  &__paid {
    margin-top: 0px;
  }
}
.footer{
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__right{
    display: flex;
    gap: 12px;
  }
}
.loading{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}