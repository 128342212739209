@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  font-family: 'Poppins';
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    margin: auto;
  }

  .reset {
    text-align: end;
    button {
      padding: 0;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $button-blue;
    }
  }

  :global {
    label.ant-form-item-no-colon {
      color: $color-grey;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    .ant-checkbox-wrapper {
      border: 1px solid #d0d4e1 !important;
    }

    .jZYyrO div.ant-checkbox-group > label.ant-checkbox-wrapper-checked {
      color: $color-primary !important;
      background: $color-blue-chalk !important;
      border: 1px solid rgba(59, 90, 196, 0.16) !important;
    }

    .ant-form-item {
      margin: 0 0 24px;
    }

    ::-webkit-scrollbar {
      margin-bottom: 8px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      transform: matrix(-1, 0, 0, 1, 0, 0);
      max-height: 50%;
      height: 50%;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      background-color: #d9d9d9;
    }
    .ant-picker-range-separator {
      svg {
        color: #b4b7c3 !important;
      }
    }

  }

  .label {
    color: $color-grey;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .header {
    padding: 0 16px 20px 16px;
  }
  .footer {
    padding: 16px 16px 0 0;
    justify-content: end;
  }
}
.range {
  :global {
    .ant-picker {
      border: 1px solid #d5d7df;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      height: 36px;
      width: 100% !important;
    }
  }
}

.amount,
.createdDate {
  :global {
    .ant-select-selection-search {
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }

    .ant-select-selector {
      min-height: 36px;
      height: auto;
      padding: 2px 12px !important;
      border-radius: 6px !important;
    }
  }
}

.datePicker {
  min-height: 36px;
  height: auto;
  padding: 2px 12px !important;
  border-radius: 6px !important;
  width: 100%;
  :global {
    .ant-picker-range-separator {
      position: relative !important;
      .ant-picker-separator {
        display: none;
      }
    }
    .ant-picker-range-separator:after {
      content: '';
      background-image: url('../../../../../public/svg/SVG/grey-right-arrow.svg') !important;
      display: block;
      height: 13px;
      background-size: cover;
      width: 13px;
      background-position: center;
    }
  }
}

.dropdown__datepicker {
  :global {
    .ant-picker-panel-container {
      border-radius: 8px;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border: 1px solid $bd-black;
      font-size: 13px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
      .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
      .ant-picker-cell-inner {
      background: $color-primary;
      border-radius: 8px;
      font-size: 13px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
      background-color: $color-blue-chalk;
      border-radius: 8px;
      color: #3b5ac4;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: none;
    }
  }
}

.itemTag {
  border: 1px solid #eceded !important;
  border-radius: 20px !important;
  background-color: #f7f7fa !important;
  display: grid !important;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  font-weight: 600;
  font-size: 12px;
  color: $color-black-grey;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  padding-left: 0 !important;
}

.circleName {
  width: 24px;
  height: 24px;
  display: inherit;
  border-radius: 50%;
  @include flex-center();

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #25397c;
  background-color: #edf0fa;
  margin-right: 8px;
  border: 1px solid #eceded;
}

.custom {
  display: flex;
  gap: 12px;
  align-items: center;

  &__text {
    color: $button-blue;
    font-weight: 400;
    font-size: 14px;
  }
}

.checkbox__option {
  display: flex;
  gap: 8px;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $color-gold-drop;
}

.transaction__status {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 0;
    }
    .ant-checkbox-wrapper {
      padding: 3px 12px 6px 14px !important;
    }
  }
}

.processing {
  background: $button-blue;
}

.confirming {
  background: $button-blue;
}

.completed {
  background: $color-success;
}

.failed {
  background: $color-text-remove;
}

.fiter__content {
  max-height: 360px;
  padding: 20px 16px;
  overflow-y: scroll;
}

.disabled__button {
  pointer-events: none !important;
  span {
    color: $color-mountain-mist;
  }
}

.range__fields {
  :global {
    .ant-form-item {
      margin: 0 0 6px !important;
    }
  }
}

.box {
  padding-bottom: 28px;
  :global {
    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }
  .box__title--error{
    color: #D8382C;
  }
  &__types {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 12px;
  }

  .groupCheckboxType {
    display: flex;
    gap: 8px;

    :global {
      .ant-checkbox-group {
        height: 44px;
      }
    }
  }

  .typeBtn {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 500;
  }

  &__statuses {
    :global {
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #d63a95 !important;
        box-shadow: 0px 2px 2px rgba(50, 68, 109, 0.06),
          0px 8px 16px rgba(2, 35, 96, 0.04);
        border-radius: 4px;
        border: none;
        &:hover,
        &:active {
          border: none !important;
        }
      }
      span.ant-checkbox {
        &:after,
        &:active {
          border: none !important;
        }
        &:hover {
          span.ant-checkbox-inner {
            border-color: #a5b2cf !important;
            &::after {
              inset-inline-start: 25%;
            }
          }
        }
        span.ant-checkbox-inner {
          &::after {
            inset-inline-start: 25%;
          }
        }
      }
    }
  }

  .groupCheckbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    label {
      margin-left: 0 !important;
    }

    span {
      line-height: 20px;
      color: #62739d;
    }
  }

  .asset {
    width: 100% !important;

    :global {
      span.ant-select-selection-item-content {
        color: #111e3c;
        font-weight: 500;
        font-size: 14px;
      }

      span.ant-select-selection-item {
        border: 1px solid #eceded;
        border-radius: 20px;
        background-color: #f7f7fa;
      }

      .ant-select-selection-search {
        -webkit-margin-start: 0 !important;
        margin-inline-start: 0 !important;
      }

      .ant-select-selector {
        min-height: 36px;
        height: auto;
        padding: 2px 12px !important;
        border-radius: 6px !important;
      }
    }
  }

  .datePicker {
    min-height: 36px;
    height: auto;
    width: 100%;
  }

  .input__group {
    margin-top: 6px;
    display: flex;
    &__min {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border: 1px solid #ced6e8;
      height: 36px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none;
      &:hover {
        border: 1px solid #ced6e8;
        border-right: none;
      }
      &__error {
        background: #fff2f1;
        border: 1px solid #ea4b3f;
        border-right: none;
        &:hover {
          border: 1px solid #ea4b3f;
          border-right: none;
        }
        :global{
          .ant-input-affix-wrapper>input.ant-input{
            background: #fff2f1;
          }
        }
      }
    }
    &__max {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border: 1px solid #ced6e8;
      height: 36px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
      padding-right: 30px;
      &:hover {
        border: 1px solid #ced6e8;
        border-left: none;
      }
      &__error {
        background: #fff2f1;
        border: 1px solid #ea4b3f;
        border-left: none;

        &:hover {
          border: 1px solid #ea4b3f;
          border-left: none;
        }
      }
      
    }
    .max{
      position: relative;
      &__suffix{
        position: absolute;
        right: 12px;
        top: 0;
        width: 16px;
        height: 100%;
        z-index: 100000;
        color: #959AAA;
        @include flex-between-center();
    }
    }

    &__arrow {
      border: 1px solid #ced6e8;
      height: 36px;
      border-radius: 0;
      border-left: none;
      border-right: none;
      pointer-events: none;
      background-color: white !important;
      background-image: url('./../../../../images/arrow-right.svg');
      background-size: 16px !important;
      display: block;
      background-position-x: 5px;
      background-position-y: 10px;
      &__error {
        background-color: #fff2f1 !important;
        border-color: #ea4b3f !important;
        border-left: none;
        border-right: none;
      }
    }
  }

  .error__text {
    color: #ea4b3f;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-top: 4px;
  }

  .iconArrowRight {
    margin-top: 10px;
  }
}
