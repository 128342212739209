@import 'styles/_variables.scss';
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
}
.content {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.button {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  :global {
    .ant-btn {
      height: 36px !important;
    }
  }
  .confirm {
    font-weight: 500 !important;
  }
}
