@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  font-family: 'Poppins';
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    margin: auto;
  }

  .reset {
    text-align: end;
    button {
      padding: 0;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $button-blue;
    }
  }

  :global {
    label.ant-form-item-no-colon {
      color: $color-grey;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .label {
    color: $color-grey;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}

.customerName {
  :global {
    span.ant-select-selection-item-content {
      font-weight: 600;
      font-size: 12px;
      color: $color-black-grey;
    }

    span.ant-select-selection-item {
      border: 1px solid #eceded;
      border-radius: 20px;
      background-color: #f7f7fa;
    }

    .ant-select-selection-search {
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }

    .ant-select-selector {
      min-height: 36px;
      height: auto;
      padding: 2px 12px !important;
      border-radius: 6px !important;
    }
  }
}

.itemTag {
  border: 1px solid #eceded !important;
  border-radius: 20px !important;
  background-color: #f7f7fa !important;
  display: grid !important;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  font-weight: 600;
  font-size: 12px;
  color: $color-black-grey;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  padding-left: 0 !important;
}

.circleName {
  width: 24px;
  height: 24px;
  display: inherit;
  border-radius: 50%;
  @include flex-center();

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #25397c;
  background-color: #edf0fa;
  margin-right: 8px;
  border: 1px solid #eceded;
}

.datePicker {
  min-height: 36px;
  height: auto;
  padding: 2px 12px !important;
  border-radius: 6px !important;
  width: 100%;
}

.dropdown__datepicker {
  :global {
    .ant-picker-panel-container {
      border-radius: 8px;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border: 1px solid $bd-black;
      font-size: 13px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
      .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
      .ant-picker-cell-inner {
      background: $color-primary;
      border-radius: 8px;
      font-size: 13px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
      background-color: $color-blue-chalk;
      border-radius: 8px;
      color: #3b5ac4;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: none;
    }
  }
}

.divFee {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 8px;
  margin-top: 8px;
}

.iconArrowRight {
  margin-top: 10px;
}

.disabled__button {
  pointer-events: none !important;
  span {
    color: $color-mountain-mist;
  }
}