@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
}

.header {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $color-black;
}

.description {
  color: $color-grey;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 6px 0;
}

.iconRemove {
  cursor: pointer;
}

.tableAUC {
  table {
    border-top: none !important;
  }
  thead > tr > th {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    background: $color-white;
    padding-left: 16px;
    padding-right: 16px;
  }
  thead,
  tbody > tr > td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    padding: 5px 16px;
  }

  thead,
  tbody > tr > td {
    :last-child {
      border: none !important;
    }
  }

  thead,
  tbody > tr > td {
    :last-child {
      border: none !important;
    }
  }
  :global {
    th.ant-table-cell {
      border-top: 1px solid #f0f0f0;
    }
    th.ant-table-cell:last-child {
      border: none !important;
    }
    thead:hover {
      .ant-table-cell:last-child {
        background-color: $color-white !important;
      }
    }
    td.ant-table-cell:last-child {
      border: none !important;
      padding-right: unset !important;
    }
    tbody {
      .ant-table-cell:last-child {
        background-color: $color-white !important;
      }
    }

    .ant-empty.ant-empty-normal {
      display: none;
    }
  }
}

.textView {
  min-height: 40px;
  line-height: 40px;
}

.btnAdd {
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  width: fit-content;
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-black-grey;
    display: flex;
  }

  .ant-table-cell::after {
    padding: none;
  }

  svg {
    margin-top: -2px;
  }
}

.msgError {
  color: $color-err;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
}

.tooltip {
  &__icon {
    font-size: 14px;
    margin-left: 8px;
    svg {
      margin-bottom: 4px;
    }
  }
}
.iconInfo {
  margin-left: 8px;
  cursor: pointer;
}
