@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.text__editor {
  height: 225px;

  :global {
    .ql-container.ql-snow {
      height: calc(200px - 66px);
    }

    .ql-editor {
      // height: 250px;
      // max-height: 250px;
      overflow: auto;
    }
  }
}
