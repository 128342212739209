@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.container {
  margin-top: 20px;
  height: 64px;
  width: 100%;
  background: $color-white-dark;
  padding: 16px 20px;
  border-bottom: 1px solid #eceded;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}
.search {
  width: 100%;
  &__search {
    height: 32px;
    border-radius: 6px !important;
    padding: 6px 12px;
    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-black-grey;
    }
    :global {
      .ant-input-prefix {
        margin-right: 0px;
      }
      .ant-input {
        padding: 0 0 0 9.5px !important;
      }
    }
  }
}
.circle__button {
  border-radius: 24px;
  height: 32px;
  width: 32px;
  border: 0.5px solid $color-grey-light;
  color: $color-grey-dark;
  text-align: center;
  cursor: pointer;
  @include flex-center;
}
.btn-search {
  :global {
    button.ant-btn.ant-btn-primary {
      padding: 6px 12px !important;
      height: 32px !important;
    }
  }
}


