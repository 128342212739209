@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.button {
  padding: 4px 6px !important;
  gap: 8px !important;
  height: 24px !important;
  border-radius: 12px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #0b0b0c !important;
  border: 1px solid #eceded !important;
  cursor: auto !important;
  &__active {
    color: #12b76a !important;
    border: 1px solid #a6f4c5 !important;
  }
  &__pending {
    color: #f79009 !important;
    border: 1px solid #fedf89 !important;
  }
}
