@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
}
.drawerProfile {
  @include custom-scroll();
  :global {
    .ant-drawer-header {
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
    }
    button.ant-drawer-close {
      margin: 0;
      padding: 0;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }

    .ant-drawer-footer {
      padding: 0;
      box-shadow: 0px 0px 16px 8px rgba(130, 150, 218, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
  }

  .customer__footer {
    justify-content: flex-end;
    padding: 20px;
  }

  .user__footer {
    gap: 8px;
    align-items: center;
    background: $color-white-dark;
    border-top: 1px solid $bd-circle-button;
    padding: 20px 18px;

    :global {
      .ant-avatar.ant-avatar-icon {
        line-height: 19px !important;
      }
    }

    .status {
      padding: 4px 8px;
      gap: 4px;
      background: $color-misty-rose;
      border-radius: 4px;
      align-items: center;
      &__text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        color: $color-grapefruit;
      }
    }

    .status__approved {
      background: $color-peppermint;
      &__text {
        color: $color-shamrock-green;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
      }
    }

    .circle__button {
      border-radius: 24px !important;
      height: 32px !important;
      width: 32px !important;
      padding: 2px 8px;
      cursor: pointer;
      &__bg {
        background-color: $color-grey-light;
      }
    }
  }
}

.popover {
  
  &__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-mist-blue;
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2c303c;
  }
}

:global {
  .ant-popover-placement-topRight {
    padding-bottom: 2px !important;
  }
}

.reject__reason {
  :global {
    .ant-popover-inner {
      border-radius: 8px;
      border: 0.5px solid #b5b6b7;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }

    .ant-popover-inner-content {
      padding: 16px !important;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.reviewing__title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-grey;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.collapse {
  .user__name {
    .role {
      padding: 2px 6px;
      gap: 6px;
      height: 18px;
      border-radius: 10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      margin-left: 8px;
      margin-top: 2px;
      color: #12b76a;
      border: 1px solid #a6f4c5;
      &__viewer {
        color: $color-black;
        border: 1px solid #eceded;
      }
      &__member {
        color: #f79009;
        border: 1px solid #fedf89;
      }
    }
    .admin__icon {
      margin-top: 1px;
      margin-left: 8px;
    }
  }

  .text__portion {
    display: flex;
    flex-direction: column;

    .label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7b84;
    }

    .text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px !important;
      color: #0b0b0c;
      margin-top: 5px;
      margin-bottom: 8px;
    }
  }
}

.tableAUC {
  thead > tr > th {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    background: $color-white;
  }
  thead,
  tbody > tr > td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }
}

.request__id {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #909193;
}

.created__by {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #909193;
}

.created__name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $color-black;
}

.created__time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909193;
}
.avatar {
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
.approval-footer {
  width: 100%;
  background-color: #f7f7f9;
}
.approval {
  width: 100%;
  padding: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: $color-white;
  .reject span {
    color: #f04438;
  }
}
.line {
  width: calc(100% - 64px);
  height: 1px;
  background-color: $bd-black;
  margin-left: 32px;
}
.approval-info {
  width: 100%;
  height: fit-content;
  padding: 12px 32px;
  background: #f7f7f9;
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #959aaa;
  .status {
    padding: 4px 8px;
    width: fit-content;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    border-radius: 4px;
  }
  .approved {
    background: #d8f8e9;
    color: #01904f;
  }
  .rejected {
    background: #fde1df;
    color: #d8382c;
  }
  .name {
    font-weight: 600;
    color: #2c303c;
  }
  .date {
    font-weight: 400;
  }
}
