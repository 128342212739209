@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.table__container {
  height: 100%;
  :global {
    .ant-table-wrapper,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-container,
    .ant-table-body {
      height: 100%;
      table {
        height: 100%;
      }
    }
    .ant-table-thead > tr > th {
      background-color: $color-white-dark;
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-grey;
      &::before {
        background-color: $color-white !important;
      }
    }
    .ant-table-thead th.ant-table-column-sort {
      background-color: $bg-sort;
    }
    .ant-table {
      border-radius: 12px;
      border: 1px solid $color-iron !important;
    }
    .ant-table-header {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .ant-table-body {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .ant-table-column-sorters {
      // justify-content: start;
      // gap: 12px;
    }
    .ant-table-column-title {
      // flex: none;
    }
    .ant-table-column-sorter {
      display: none;
    }
    .ant-table-tbody > tr > td {
      padding: 10.5px 16px;
      line-height: 20px;
    }

    tbody tr:hover {
      cursor: pointer;
    }
    span.anticon.anticon-loading.anticon-spin.ant-spin-dot {
      margin-left: -35px;
    }
  }

  .sorter-no-tooltip .ant-table-column-sorters:before {
    content: none !important;
  }

  ::-webkit-scrollbar {
    margin-bottom: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    max-height: 50%;
    height: 50%;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-color: #d9d9d9;
  }
  transition: max-height 0.6s;
  :global {
    .ant-table table {
      table-layout: fixed !important;
    }
  }
}

.disabled__header {
  :global {
    .ant-table-thead {
      pointer-events: none;
    }
  }
}

.rowActive td {
  background-color: $color-bg-blue !important;
  border-top: thin solid $color-info !important;
  border-bottom: thin solid $color-info !important;
}
