@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.drawerProfile {
  :global {
    .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .ant-drawer-header {
      padding: 22px 24px 22px 32px;
    }
    .ant-drawer-body {
      padding: 32px;
      padding-top: 16px;
      display: flex;
      flex-direction: column;
    }
    .ant-drawer-footer {
      padding: 0;
      box-shadow: 0px 0px 16px 8px rgba(130, 150, 218, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
  }
  @include custom-scroll();
}

.container {
  background: #ffffff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 1rem;
}

.top {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin: 16px 0 28px 0;
}

.name {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
  margin-bottom: 0;
}
.description {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }

  &__viewRequest {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #2e90fa;
    &:hover {
      cursor: pointer;
    }
    padding: auto;
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 12px;
}

.heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0b0b0c;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #959aaa;
    padding-top: 3px;
  }
  .icon {
    cursor: pointer;
  }
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__label {
    font-weight: 400;
    margin-bottom: 4px;
    text-transform: initial !important;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2c303c;
    margin: 0;
  }
}

.device {
  display: flex;
  align-items: center;
  &__iconSvg {
    margin-left: 4px;
  }
}

.role {
  padding: 4px 10px;
  height: 24px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;

  &__admin {
    color: #12b76a;
    border: 1px solid #a6f4c5;

    &--required {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px 1px 8px;
      gap: 2px;
      background: #ffe7f5;
      border-radius: 24px;
      height: 20px;
      margin-left: 8px;
    }
  }
  &__viewer {
    color: $color-black;
    border: 1px solid #eceded;
  }
  &__member {
    color: $button-blue;
    border: 1px solid $bd-prinary;
  }

  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: $color-secondary;
    text-transform: uppercase;
  }

  &__icon {
    margin-left: 5px;
  }
}

.status {
  width: fit-content;
  height: 24px;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: 12px;

  &__active {
    color: $color-success;
    border: 1px solid $bd-active;
  }

  &__inactive {
    color: $color-black;
    border: 1px solid $bd-black;
  }

  &__awaitOnboarding {
    color: $button-orange;
    border: 1px solid $bd-pending;
  }

  &__failOnboarding {
    color: $button-red;
    border: 1px solid $button-red;
  }

  &__locked {
    color: $color-gold-drop;
    border: 1px solid $color-gold-drop;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
}

.sectionVaults {
  display: flex;
  margin-top: 8px;
  gap: 8px;
  position: relative;
  max-height: 168px;
  overflow: hidden;
}

.vaultsShadow {
  position: absolute;
  height: 56px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 86.61%
  );
}

.allVaults {
  height: 168px;
  overflow-y: scroll !important;
  @include custom-scroll-popup();
}

.vault {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 30px;
  padding: 0px 16px 0px 0px;
  border: 1px solid #e3e6f0;
  max-width: fit-content;
  display: flex;
  flex-wrap: nowrap !important;
  &__icon {
    background: #f3f3f4;
    border-radius: 145.455px;
    width: 32px;
    height: 32px;
    text-align: center;
    svg {
      margin-top: 8px;
      color: $color-primary;
    }
  }

  &__name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
    margin: auto;
    margin-left: 8px;
    white-space: nowrap;
  }
}

.showAll {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $button-blue;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
}

.footer {
  padding: 24px;
  padding-bottom: 0px;
}

.button {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-column-gap: 12px;

  &__removeAccess {
    border: 1px solid #d5d7df !important;
    span {
      color: #d8382c !important;
    }
  }

  &__lostDevice {
    background-color: #e5edfc !important;
    span {
      color: #25397c !important;
    }
  }

  &__edit {
  }
}

.loading {
  height: calc(100vh - 165px);
  @include flex-center();
}

.arrowIcon {
  fill: $button-blue;
  margin-top: 8px;
  margin-left: 8px;
}
