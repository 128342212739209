@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.tab {
  padding-top: 12px;

  .total {
    .total_role_label_1 {
      font-size: 10px;
      line-height: 14px;
      color: #7b7b84;
    }

    .total_role_label_2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .require__approver__icon {
    margin-left: 2px;
    margin-bottom: 4px;
  }

  .collapse {
    margin-top: 8px;
    :global {
      .ant-collapse-header {
        cursor: default !important;
      }
    }
    .user__name {
      .role {
        padding: 2px 6px;
        gap: 6px;
        height: 18px;
        border-radius: 10px;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        margin-left: 8px;
        margin-top: 2px;
        color: #12b76a;
        border: 1px solid #a6f4c5;
        &__viewer {
          color: $color-black;
          border: 1px solid #eceded;
        }
        &__member {
          color: #f79009;
          border: 1px solid #fedf89;
        }
      }
      .require__admin__icon {
        margin-top: 1px;
        margin-left: 8px;
      }
    }

    .info__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7b84;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px !important;
    }
  }
}
