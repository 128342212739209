@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.detail {
  display: grid;
  grid-row-gap: 28px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 12px;
  font-weight: bold;
}

.list_comment {
  margin: 20px 0;
  display: grid;
  grid-row-gap: 20px;
}

.container {
  background: #ffffff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 1rem;
}
