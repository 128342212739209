.role {
    display: flex;
    align-items: center;
    gap: 8px;
    &__admin {
      color: #2e90fa;
    }
    // ...
    .required {
      display: flex;
      gap: 5px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #d63a95;
      padding: 4px 8px;
      width: fit-content;
      height: 20px;
      background: #ffe7f5;
      border-radius: 24px;
      align-items: center;
    }
  }