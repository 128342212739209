@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.btn {
  :global {
    .ant-switch-checked {
      background-color: $color-success !important;
    }
  }
}
