@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.customer-inform {
    margin-top: 28px;

    &__label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64697b;
    }

    &__content {
        margin-top: 6px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #2e90fa;
        cursor: pointer;
    }
}

.drawerProfile {
    :global {
        .ant-drawer-header {
            padding-left: 20px;
            padding-right: 20px;
            box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        }

        .ant-drawer-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $color-black;
        }

        .ant-drawer-header-title {
            display: flex;
            flex-direction: row-reverse;
        }

        button.ant-drawer-close {
            margin: 0;
            padding: 0;
        }

        .ant-drawer-body {
            padding: 24px 20px;
        }
    }
}

.profile {
    &__top {
        display: flex;
        align-items: center;
    }

    .name {
        max-width: calc(100% - 40px);
        @include text-ellipsis-in-line();

        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: $color-black;
        margin-right: 1rem;
    }
}

.name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
    margin: auto;
    margin-left: 8px;
    white-space: nowrap;
}