@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.reviewing__title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
  margin-top: 8px;
  margin-bottom: 24px;
  align-items: center;
  gap: 8px;
}

.user__info {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e3e6f0;
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
    margin-left: 14px;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-grey;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }

  .customer__detail {
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $button-blue;
    cursor: pointer;
  }
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.profile {
  &__top {
    display: flex;
    align-items: center;
  }

  .name {
    max-width: calc(100% - 40px);
    @include text-ellipsis-in-line();

    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: $color-black;
    margin-right: 1rem;
  }
}

.drawerProfile {
  :global {
    .ant-drawer-header {
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
    }
    button.ant-drawer-close {
      margin: 0;
      padding: 0;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }

    .ant-drawer-content-wrapper {
      z-index: 10000;
    }
  }
}

.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.bold__name_trans_detail {
  color: $color-black;
  font-weight: 600;
}

.sub__name_trans_detail {
  color: $color-grey;
}

.normal__name_trans_detail {
  font-weight: 400;
}

.status {
  width: fit-content;
  padding: 4px 10px;
  font-weight: 600;

  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
  background-color: #b8b8c1a1;
}
.pending {
  color: $button-orange;
  background-color: #fcf0d8;
}
.failed {
  color: $button-red;
  background: #fde1df;
}
.processing {
  color: $button-blue;
  background: #deedfd;
}
.completed {
  color: $button-green;
  background: #d8f8e9;
}
.confirming {
  color: $button-blue;
  background: #deedfd;
}

.status__title {
  padding: 4px 9px;
  gap: 4px;
  background: $color-carmine-pink;
  border-radius: 24px;
  height: 24px;
  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: white;
  }
}

.status__title__approved {
  background: $color-success;
}

.icon {
  width: 20px;
  height: 20px;
  border: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    margin-top: -5px;
  }
}

.copy__icon_trans_detail {
  cursor: pointer;
}
