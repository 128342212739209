@import 'styles/_variables.scss';

.container {
  :global {
    .ant-modal-body {
      padding: 24px;
    }
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0c0d12;
  }
  .content {
    margin-top: 48px;
    display: flex;
    column-gap: 12px;
    margin-bottom: 48px;
    .passport {
      .image {
        width: 260px;
        img {
          width: 100%;
          height: 100%;
          max-height: 368px;
          object-fit: cover;
        }
      }
    }
    .contentNonPassport {
      width: 100%;
      .contentNonPassportImage {
        display: flex;
        justify-content: space-between;   
      }
      .contentNonPassportInfo {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        .info {
          .title {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #495B86;
            letter-spacing: 0em;
          }
          .content {
            margin-bottom: 0px;
            margin-top: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0em;
            color: #2c303c;
            display: flex;
            align-items: center;
            .status {
              margin-left: 8px;
              padding: 4px 8px;
              font-weight: 600;
              font-size: 11px;
              line-height: 16px;
              border-radius: 4px;
              color: $color-black;
              background: #cfcfd1;
            }
            .approved {
              color: #01904f;
              background: #d8f8e9;
            }
            .rejected {
              color: #d8382c;
              background: #fde1df;
            }
          }
          .speech {
            color: #2c303c;
            font-weight: 600;
          }
        }
      }
    }
    .onboarding,
    .authentication {
      max-width: 194px;
      img {
        width: 194px;
        height: 259px;
        object-fit: cover;
      }
      .text {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $color-grey;
        text-align: center;
      }
    }
  }
}
