@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  padding: 0 12px;
  padding-bottom: 0px;
  position: relative;
}

.title {
  margin-top: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0b0b0c;
  display: flex;
  align-items: center;

  .status {
    margin-left: 16px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: fit-content;
    gap: 8px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }

  .approved {
    background: #00a55b;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .rejected {
    background: #ea4b3f;
    svg {
      width: 9px;
      height: 9px;
    }
  }
}

.group_table {
  border-bottom: none;
  :global {
    .ant-table-thead th.ant-table-column-sort {
      background-color: $bg-sort;
    }
    .ant-table {
      border-radius: 12px;
      border: 1px solid #e5edfc;
    }
    .ant-table-header {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .ant-table-thead .ant-table-cell {
      background-color: $color-tealish-blue;
    }
    .ant-table-body {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .ant-table-container,
    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 12px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 12px;
    }
  }
}

.check_box_access_control {
  cursor: default !important;
  :global {
    .ant-checkbox {
      background: white;
      border-radius: 4px;
      cursor: default !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $button-pink;
      border-color: $button-pink;
      border: none !important;
      border-radius: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 1px;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked:focus .ant-checkbox-inner {
      border-color: $button-pink !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: white !important;
    }
  }
}

.user__info {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e3e6f0;
  margin-top: 28px;
}