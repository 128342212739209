.table_container_1 {
    margin-top: 24px;
    height: 85%;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;

    :global {
        .ant-spin-nested-loading {
            margin: 0px !important;
        }

        .ant-table-cell {
            padding: 20px 24px;
        }

        .ant-table-row {
            .ant-table-cell {
                padding: 10.5px 25px;
                height: 110px;
            }
        }

        .ant-table-container table>thead>tr:first-child th:first-child {
            border-top-left-radius: 12px;
        }

        .ant-table-container table>thead>tr:last-child th:last-child {
            border-top-right-radius: 12px;
        }
    }
}

.feature_item {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.desc_item {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.enable_item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}