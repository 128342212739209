@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.reviewing__title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
  margin-top: 8px;
  margin-bottom: 24px;
  align-items: center;
  gap: 8px;
}

.user__info {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e3e6f0;
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
    margin-left: 14px;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-grey;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }

  .customer__detail {
    padding: 0;
    font-weight: 600;
    color: $button-blue;
  }
}

.text__portion {
  display: flex;
  flex-direction: column;

  .label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7b7b84;
  }

  .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px !important;
    color: #0b0b0c;
    margin-top: 5px;
    margin-bottom: 8px;
  }
}

.status {
  padding: 4px 9px;
  gap: 4px;
  background: $color-carmine-pink;
  border-radius: 24px;
  height: 24px;
  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: white;
  }
}

.status__approved {
  background: $color-success;
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.profile {
  &__top {
    display: flex;
    align-items: center;
  }

  .name {
    max-width: calc(100% - 40px);
    @include text-ellipsis-in-line();

    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: $color-black;
    margin-right: 1rem;
  }
}

.drawerProfile {
  :global {
    .ant-drawer-header {
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
    }
    button.ant-drawer-close {
      margin: 0;
      padding: 0;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }
  }
}

.monthly__fee {
  gap: 7px;
  align-items: center;

  .current__fee {
    color: $color-mist-blue;
    text-decoration: line-through;
  }

  .updated__fee {
    color: $color-deep-cerise;
  }
}
