@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.modal {
  :global {
    .ant-modal-header {
      border-bottom: none;
      .ant-modal-title {
        font-size: 16px !important;
      }
    }
  }
}

.button {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  :global {
    .ant-btn {
      height: 36px !important;
    }
  }
  .confirm {
    font-weight: 500 !important;
  }
}

.input {
  &__staking {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  &__bg {
    height: 44px;
    background: #f7f8fb;
    border-radius: 8px;
    padding: 0 12px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 8px;
    align-items: center;
    margin-bottom: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    margin-top: 8px;
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    color: $color-grey;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;

    &--active {
      color: $color-primary;
    }
  }
}
