@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.mainModal {
  :global {
    .ant-modal-content {
      overflow: auto;
      border-radius: 12px;
    }
    .ant-modal-header {
      padding: 20px;
      .ant-modal-title {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;
      }
    }
    .ant-modal-body {
      padding: 20px;
    }
  }
}
