@import 'styles/_variables.scss';

.modal {
  :global {
    .ant-modal-content {
      border-radius: 12px !important;

      .ant-modal-footer {
        padding: 20px !important;
        border-top: none;
      }

      .ant-modal-close {
        display: none !important;
      }
    }
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0b0b0c;
  }

  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7b7b84;
    margin-top: 8px;
  }
}
