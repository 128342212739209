@import 'styles/_variables.scss';

.new-application-modal {
  width: 600px !important;
  height: 921px !important;
  .ant-modal-body {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
  .ant-modal-header {
    border-bottom: 0;
    padding: 24px;
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 24px;
  }

  textarea.ant-input {
    resize: none;
    height: 84px;
  }

  .devider {
    margin-top: 8px;
    height: 1px;
    width: 100%;
    background: $color-neutral-100;
  }
  .section-title {
    display: block;
    margin: 24px 0;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-col.ant-form-item-label {
    label {
      font-weight: 600;
      font-size: 12px;
      color: #495b86;
    }
  }

  .ant-form-item.ant-form-item-has-error {
    .ant-form-item-explain-connected {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-upload-text,
  .ant-upload-hint {
    font-size: 14px;
  }

  .ant-upload-text {
    color: $color-neutral-700;
    font-weight: 600;
    line-height: 20px;
  }
  .ant-upload-hint {
    font-weight: 400;
    color: $color-neutral-500;
  }
  .ant-checkbox-group {
    label.ant-checkbox-wrapper {
      &:nth-child(2) {
        margin-left: 132px;
      }
    }
    .checkbox-content {
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 14px;
        line-height: 20px;
        margin-left: 8px;
      }
    }
  }

  .show-logo-container {
    img {
      height: 48px;
      width: 48px;
      margin-bottom: 16px;
    }
    .ant-typography {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: unset;
    }
  }
}
