@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.content {
    &__header {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 20px;
        color: $color-black !important;
    }

    &__item {
        margin-bottom: 20px;
    }

    &__label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
        color: $color-grey;
        text-transform: initial !important;
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;
        margin: 0;
    }
}

.dot {
    background-color: #6B6D6F;
    border-radius: 50%;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
}