@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.status {
  margin-left: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  width: fit-content;
  gap: 8px;
  border-radius: 24px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.approved {
  background: #00a55b;
  svg {
    width: 16px;
    height: 16px;
  }
}
.rejected {
  background: #ea4b3f;
  svg {
    width: 9px;
    height: 9px;
  }
}

.customer-inform {
  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }

  &__content {
    margin-top: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2e90fa;
    cursor: pointer;
  }
}
