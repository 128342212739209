@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.search {
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  gap: 8px;
  &__icon {
    position: absolute;
    @include abs-top-left($top: 11.5px, $left: 13.5px);
  }
  &__clear {
    position: absolute;
    cursor: pointer;
    @include abs-top-right($top: 9px, $right: 13px);
  }

  &__have--btn {
    @include abs-top-right($top: 9px, $right: 98px);
  }

  &__input {
    border: 1px solid $border-color-default;
    border-radius: 8px;
    padding: 8px 34px 8px 34px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 22px;
    background: #ffffff;
    border: 1px solid #d0d4e1;

    color: #7b7b84;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &:focus {
      outline: none;
    }
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #7b7b84;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7b7b84;
  }

  ::placeholder {
    color: #7b7b84;
  }

  .search_AuditLogList {
    &::placeholder {
      color: #959aaa;
      opacity: 1;
    }
  }
}
