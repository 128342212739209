@mixin text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin text-ellipsis-in-line($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin abs-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin abs-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin abs-top-left($top: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin abs-top-right($top: 0, $right: 0) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abs-bottom-right($bottom: 0, $right: 0) {
  position: absolute;
  bottom: $bottom;
  right: $right;
}

@mixin abs-bottom-left($bottom: 0, $left: 0) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

@mixin abs-left-center($left: 0) {
  position: absolute;
  top: 50%;
  left: $left;
  transform: translateY(-50%);
}

@mixin abs-right-center($right: 0) {
  position: absolute;
  top: 50%;
  right: $right;
  transform: translateY(-50%);
}

@mixin grid-center {
  display: grid;
  place-items: center;
  place-content: center;
}

@mixin grid-between {
  display: grid;
  align-content: space-between;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin translate-center {
  transform: translate(-50%, -50%);
}

@mixin translate-vertical-center {
  transform: translateY(-50%);
}

@mixin custom-scroll {
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $color-bg-scroll-gray;
    border-radius: 10px;
  }
}

@mixin custom-scroll-popup {
  @include custom-scroll;
  &::-webkit-scrollbar {
    margin-bottom: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #ced6e8;
  }
}
