.content {
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 28px;
  }
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 12px;
  }
  .item {
    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7b84;
    }
    &__content {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #0b0b0c;
      margin-top: 8px;
      display: flex;
      align-items: center;
      .status{
        margin-left: 8px;
        padding: 4px 8px;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        border-radius: 4px;
      }
      .approved, .reviewed{
        color: #01904F;
        background: #D8F8E9;
      }
      .rejected{
        color: #D8382C;
        background: #FDE1DF;
      }

    }
  }
}
