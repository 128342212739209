@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  width: 100%;
  height: auto;
  padding-top: 8px;
}

.menu {
  border-radius: 6px !important;
  min-width: 216px;
}

.title {
  position: relative;
  width: fit-content;
  height: auto;
  .icon {
    position: absolute;
    right: -20px;
    top: 0px;
  }
}

.table {
  thead > tr > th {
    background: $color-white;
  }
  @include custom-scroll();
  :global {
    .ant-table-thead > tr > th {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0b0b0c;
      &::before {
        background-color: $color-white !important;
      }
    }

    .ant-table-column-sorter {
      display: none;
    }

    .ant-table {
      border: 1px solid $bd-black;
      border-top: none;
    }
    .ant-table-column-sorters {
      justify-content: start;
      gap: 12px;
    }
    .ant-table-column-title {
      flex: none;
    }
  }
  :global {
    .ant-dropdown-menu {
      border-radius: 6px !important;
    }
  }

  // tbody tr:hover {
  //   cursor: pointer;
  // }

  // tbody > tr:hover td {
  //   background-color: $color-bg-blue !important;
  //   border-top: thin solid $color-info !important;
  //   border-bottom: thin solid $color-info !important;
  // }
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
}

.email {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
  @include text-ellipsis;
}

.admin,
.member,
.viewer,
.active,
.inactive,
.failedOnboarding,
.pendingVerify,
.awaitOnboarding {
  width: fit-content;
  height: 24px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: 12px;
  // text-transform: capitalize;
}

.admin,
.active {
  color: $color-success;
  border: 1px solid $bd-active;
}

.viewer,
.inactive {
  color: $color-black;
  border: 1px solid $bd-black;
}

.member {
  color: $button-blue;
  border: 1px solid $bd-prinary;
}

.failedOnboarding {
  color: $color-text-remove;
  border: 1px solid $color-sweet-pink;
}

.pendingVerify,
.awaitOnboarding {
  color: $button-orange;
  border: 1px solid $bd-pending;
}

.icon {
  text-align: center;
  svg {
    cursor: pointer;
  }
}

.action {
  display: flex;
  padding: 7px 0;
  border-radius: 6px;
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }
  .remove {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-remove;
  }
}
.empty {
  text-align: center;
  margin-top: 132px;
  padding-bottom: 172px;
  height: 100%;
  .title {
    margin-top: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $color-black;
  }
  .content {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }
}
.remove-scroll {
  :global {
    .ant-table-body {
      overflow-y: hidden !important;

      .ant-table-placeholder {
        display: none;
      }
    }
  }
}

.rowActive td {
  background-color: $color-bg-blue !important;
  border-top: thin solid $color-info !important;
  border-bottom: thin solid $color-info !important;
}
