@import 'styles/_variables.scss';
.input {
  padding: 7px 12px !important;
  background: $color-white;
  border: 1px solid #D0D4E1;
  border-radius: 6px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $color-black !important;
  :global{
    .ant-input-prefix{
      margin-right: 8px !important;
    }
    .ant-input-suffix{
      margin-left: 8px !important;
    }
  }
  &:hover{
    background: #F6F6F6 !important;
    border: 1px solid #DADADB !important;
    :global{
      .ant-input{
        background-color: #F6F6F6 !important;
        color: #64697B !important;
        outline: none !important;
      }
    }
  }
}
