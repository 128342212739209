@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.reviewing__title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
  margin-top: 8px;
  margin-bottom: 24px;
  align-items: center;
  gap: 8px;
}

.user__info {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e3e6f0;
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
    margin-left: 14px;
  }

  &__item {
    margin-bottom: 20px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-grey;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }

  .customer__detail {
    padding: 0;
    font-weight: 600;
    color: $button-blue;
  }
}

.role {
  padding: 4px 10px;
  height: 20px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #12b76a;
  border: 1px solid #a6f4c5;
  &__viewer {
    color: $color-black;
    border: 1px solid #eceded;
  }
  &__member {
    color: #f79009;
    border: 1px solid #fedf89;
  }
}

.required__approver {
  justify-content: center;
  align-items: center;
  padding: 0 8px 1px 8px;
  gap: 2px;
  background: #ffe7f5;
  border-radius: 24px;
  height: 20px;
  margin-left: 8px;

  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #d63a95;
    margin-bottom: 1px;
  }

  &__icon {
    margin-left: 5px;
  }
}

.text__portion {
  display: flex;
  flex-direction: column;

  .label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7b7b84;
  }

  .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px !important;
    color: #0b0b0c;
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .sectionVaults {
    display: flex;
    margin-top: 8px;
    gap: 8px;
    position: relative;
    :global{
      .ant-row{
        width: 100%;
      }
    }
  }

  .vaultsShadow {
    position: absolute;
    height: 56px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 86.61%
    );
  }
  .title{
    padding: 8px 0px 0px 0px !important;
  }
}

.vault {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 30px;
  padding: 0px 16px 0px 0px;
  border: 1px solid #e3e6f0;
  max-width: fit-content;
  flex-flow: nowrap !important;
  &__icon {
    background: #f3f3f4;
    border-radius: 145.455px;
    width: 32px;
    height: 32px;
    text-align: center;
    svg {
      margin-top: 8px;
      color: $color-primary;
    }
  }

  &__name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
    margin: auto;
    margin-left: 8px;
    white-space: nowrap;
  }
}

.user__name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
}

.status {
  padding: 4px 9px;
  gap: 4px;
  background: $color-carmine-pink;
  border-radius: 24px;
  height: 24px;
  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: white;
  }
}

.status__approved {
  background: $color-success;
}

.loading {
  max-height: 100vh;
  height: 100%;
  min-height: 400px;
  @include flex-center();
}

.profile {
  &__top {
    display: flex;
    align-items: center;
  }

  .name {
    max-width: calc(100% - 40px);
    @include text-ellipsis-in-line();

    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: $color-black;
    margin-right: 1rem;
  }
}

.drawerProfile {
  :global {
    .ant-drawer-header {
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
    }
    button.ant-drawer-close {
      margin: 0;
      padding: 0;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }
  }
}
.title{
  font-weight: 600;
  font-size: 14px;
  color: $color-black;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  padding: 8px 0 20px 0;
  width: 100%;
  .title__line{
    width: 100%;
    background: $color-dawn-pink;
    height: 1px;
  }
}
.fieldChanged{
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  .old{
    font-weight: 400;
    font-size: 14px;
    color: #64697B;
    text-decoration: line-through;
  }
  .new{
    font-weight: 400;
    font-size: 14px;
    color: $color-secondary;
  }
}
.identity-document{
  font-weight: 400;
  font-size: 14px;
  svg{
    margin-right: 8px;
  }
}
.icon_role{
  padding: 0 8px 0 12px;
}

.passport_id {
  display: flex;
  align-items: center;
  &__icon {
      margin-right: 8px;
  }
}