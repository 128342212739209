@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.content {
    border-radius: 8px;
    border: 1px solid var(--background-default, #ECEDED);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    background: var(--background-default, #ECEDED);
    padding: 8px;

    &__name {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }
}

.list {
    margin: 16px;
}