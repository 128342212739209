@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  padding: 0 12px;
  padding-bottom: 0px;
  margin-top: -4px;
  height: 100%;
  position: relative;
}
.title {
  margin-top: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0b0b0c;
  display: flex;
  align-items: center;
  .status {
    margin-left: 16px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: fit-content;
    gap: 8px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }
  .approved {
    background: #00a55b;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .rejected {
    background: #ea4b3f;
    svg {
      width: 9px;
      height: 9px;
    }
  }
}
.customer-inform {
  margin-top: 28px;
  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }
  &__content {
    margin-top: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2e90fa;
    cursor: pointer;
  }
}
.content {
  background: #ffffff;
  border: 1px solid #e3e6f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-top: 28px;
}
.user-inform {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0c0d12;
  }
}

.info {
  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
    margin-bottom: 6px;
    padding-top: 14px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2c303c;
    margin-bottom: 0px;
  }
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2c303c;
}
.role {
  display: flex;
  align-items: center;
  gap: 8px;
  &__admin {
    color: #2e90fa;
  }
  // ...
  .required {
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #d63a95;
    padding: 4px 8px;
    width: fit-content;
    height: 20px;
    background: #ffe7f5;
    border-radius: 24px;
    align-items: center;
  }
}
.contact-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-column-gap: 8px;
  margin-bottom: 16px;
  margin-top: 24px;
  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0b0b0c;
    width: 100%;
  }
  &__line {
    height: 1px;
    background-color: #ebecf0;
  }
}

.drawerProfile {
  :global {
    .ant-drawer-header {
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
    }
    button.ant-drawer-close {
      margin: 0;
      padding: 0;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }
  }
}

.profile {
  &__top {
    display: flex;
    align-items: center;
  }

  .name {
    max-width: calc(100% - 40px);
    @include text-ellipsis-in-line();

    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: $color-black;
    margin-right: 1rem;
  }
}

.text__portion {
  padding-top: 24px;
  display: flex;
  flex-direction: column;

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7b7b84;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px !important;
    color: #0b0b0c;
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .sectionVaults {
    display: flex;
    margin-top: 8px;
    gap: 8px;
    position: relative;
  }

  .shadowVaults {
    position: absolute;
    height: 56px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 86.61%
    );
  }
}

.vault {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 30px;
  padding: 0px 16px 0px 0px;
  border: 1px solid #e3e6f0;
  max-width: fit-content;
  flex-flow: nowrap !important;
  &__icon {
    background: #f3f3f4;
    border-radius: 145.455px;
    width: 32px;
    height: 32px;
    text-align: center;
    svg {
      margin-top: 8px;
      color: $color-primary;
    }
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
    margin: auto;
    margin-left: 8px;
    white-space: nowrap;
  }
}

.passport_id {
  display: flex;
  align-items: center;
  &__icon {
      margin-right: 8px;
  }
}