@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.approve__popup {
  :global {
    .ant-modal-header {
      border-bottom: none;
      padding: 20px 20px 0 20px;
      .ant-modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
      }
    }
    .ant-modal-body {
      padding: 10px 20px 0px 20px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 20px;
      padding-top: 10px;
    }
    .ant-modal-close {
      display: none !important;
    }
  }

  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }
}

.button {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  :global {
    .ant-btn {
      height: 36px !important;
    }
  }
  .confirm {
    font-weight: 500 !important;
  }
}
