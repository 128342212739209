@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.total {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .total__common {
    width: 100%;
    height: auto;
    display: flex;
    &::before {
      content: '';
      height: 100%;
      width: 1px;
      background: $bg-grey;
    }
    .total__number {
      padding-left: 24px;
    }
    &--total {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: $color-black;
      margin-top: 4px;
      margin-left: 12px;
    }
  }
  &__actitve {
    width: 100%;
    height: auto;
    display: flex;
    &--number {
      height: 40px;
      background: $color-primary;
      border-radius: 4px;
      width: auto;
      padding: 4px 12px;
      font-weight: 600;
      font-size: 32px;
      color: $color-white;
      margin-top: 8px;
      @include flex-center;
    }
  }
}
.content {
  padding-left: 12px;
  padding-top: 10px;
  &__total {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: $color-grey;
  }
  &__type {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black-grey;
    margin-top: 2px;
    white-space: nowrap;
  }
}
