@import 'styles/_variables.scss';
.container {
  width: 100%;
  height: 100% !important;
  height: auto;
  .heading {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    height: 48px;
  }

  .title {
    font-size: 28px;
    line-height: 32px;
    color: $color-black;
    font-weight: 700;
    margin-bottom: 0;
  }

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
      font-size: 13px;
      line-height: 16px;
      margin-left: 8px;
      font-weight: 500;
    }
  }
}
