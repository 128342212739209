@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.status {
    width: fit-content;
    height: 24px;
    padding: 4px 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 12px;
  
    &__active {
      color: $color-success;
      border: 1px solid $bd-active;
    }
  
    &__inactive {
      color: $color-black;
      border: 1px solid $bd-black;
    }
  
    &__awaitOnboarding {
      color: $button-orange;
      border: 1px solid $bd-pending;
    }
  
    &__failOnboarding {
      color: $button-red;
      border: 1px solid $button-red;
    }
  }