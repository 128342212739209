@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.noAccount {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $color-cool-grey;
    margin-bottom: 0;
    margin-top: 20px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $color-cool-grey;
    margin: 8px 0;
  }
  .buttons {
    margin-top: 24px;
    &__refresh {
      max-width: 140px;
      height: 52px;
      border-radius: 12px;
      font-family: 'Poppins';
      padding: 0px 29px;
      height: 52px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: white;
      background: $color-primary;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }

    &__add {
      height: 52px;
      border-radius: 12px;
      font-family: 'Poppins';
      margin-top: 20px;
      border: 1px solid $color-primary;
      background-color: white;
      color: $color-primary;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 16px 21px 16px 23px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
