.exportBasicModal {
  :global {
    .ant-modal-header,
    .ant-modal-footer {
      border: 0;
    }

    .ant-modal-header {
      padding: 24px;
      padding-bottom: 12px;
    }

    .ant-modal-body {
      padding: 0 24px 24px;
    }

    .ant-modal-footer {
      padding: 24px;
    }
  }
}
