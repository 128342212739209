@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.drawerTransaction {
  :global {
    .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .ant-drawer-header {
      padding: 22px 24px 22px 32px;
    }
    .ant-drawer-body {
      padding: 32px;
      padding-top: 16px;
    }
    .ant-drawer-footer {
      padding: 0;
      box-shadow: 0px 0px 16px 8px rgba(130, 150, 218, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
  }
  @include custom-scroll();
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #959aaa;
    padding-top: 3px;
    align-items: center;
    display: flex;
  }
  .icon {
    cursor: pointer;
  }
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.checklist {
  position: absolute;
  width: 360px;
  padding-bottom: 20px;
  top: 44px;
  left: -370px;
  background: white;
  border-left: 1px solid $bd-black;

  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  border-radius: 8px;
}

.loading {
  height: calc(100vh - 165px);
  @include flex-center();
}

.transaction__footer {
  justify-content: flex-end;
  padding: 20px;
}

.mark__reviewed {
  background-color: $color-iceberg !important;
  span {
    color: $color-primary !important;
    font-size: 13px !important;
    font-weight: 500;
  }
}

.mark__reviewed:hover {
  background-color: $color-tropical-blue !important;
}

.mark__reviewed:disabled {
  background-color: #ebecf0 !important;
  span {
    color: #b4b7c3 !important;
    font-size: 13px !important;
    font-weight: 500;
  }
}

.reject__button {
  border: 1px solid #fda29b !important;
  span {
    color: #f04438 !important;
  }
}

.user__footer {
  gap: 8px;
  align-items: center;
  background: $color-white-dark;
  border-top: 1px solid $bd-circle-button;
  padding: 20px 18px;

  .status {
    padding: 4px 8px;
    gap: 4px;
    background: $color-pearl;
    border-radius: 24px;
    &__text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      color: $button-green;
    }
  }

  .status__approved {
    background: $color-aqua-spring;
    &__text {
      color: $button-green;
    }
  }
  :global {
    svg {
      margin-bottom: 5px;
    }
  }

  .created__by {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #909193;
  }

  .created__name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
  }

  .created__time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #909193;
  }

  .image {
    width: 28px;
    height: 28px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }
}

.eye_icon {
  cursor: pointer;
  color: $color-grey-dark;
  margin-left: 8px;
}

.sync-transaction {
  border: none !important;
}

.button {
  border: 0.5px solid $color-grey-light !important;
  color: $color-neutral-900 !important;
  background: $color-white;
  height: 36px;
  border-radius: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;

  &:hover {
    background-color: $color-white !important;
    border: 0.5px solid $color-grey-light !important;
    color: $color-primary !important;
  }

  &:focus {
    background-color: $color-white !important;
    border: 0.5px solid $color-grey-light !important;
    color: $color-primary !important;
  }
}

.disabled {
  background-color: $color-neutral-100 !important;
  border: none !important;
  color: $color-neutral-300 !important;

  &:hover {
    background-color: $color-neutral-100 !important;
    border: none !important;
    color: $color-neutral-300 !important;
  }
}
