@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.icon {
    background: #f3f3f4;
    border-radius: 145.455px;
    width: 32px;
    height: 32px;
    text-align: center;
}

.title {
    margin-top: 8px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b0b0c;
    display: flex;
    align-items: center;

    .status {
        margin-left: 16px;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        width: fit-content;
        gap: 8px;
        border-radius: 24px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
    }

    .approved {
        background: #00a55b;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .rejected {
        background: #ea4b3f;

        svg {
            width: 9px;
            height: 9px;
        }
    }
}