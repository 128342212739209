@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
.container {
  width: 100%;
  height: 100% !important;
  height: auto;
}
.heading {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  height: 48px;
}
.next__tab {
  flex-grow: 1;
  text-align: end;
  button {
    font-size: 13px;
    padding: 0;
    gap: 9px;
    display: inline-flex;
    align-items: center;
  }
}

.heading_filter {
  margin: 24px 0;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $color-black;
}
.total {
  display: flex;
  gap: 4px;
  align-items: center;
  background: #fae1ef;
  border-radius: 24px;
  padding: 4px 8px;

  &__number {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #d63a95;
  }
  &__empty {
    color: $color-cool-grey;
    background-color: #fae1ef;
  }
}
.right {
  float: right;
  background-color: red;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-grey;
}
.download-confirm {
  :global {
    .ant-modal-header {
      padding: 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 24px 20px;
    }
    .ant-modal-footer {
      padding: 24px;
      border-top: none;
    }
  }
}
