
.container{
  width: fit-content;
  height: auto;
  background: #EBECF0;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #64697B;
}
.admin{
  background: #DEEDFD;
  color: #2E90FA;
}
.member{
  background: #E5EDFC;
  color: #25397C;
}
.viewer{
  background: #EBECF0;
  color: #64697B;
}