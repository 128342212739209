@import 'styles/_variables.scss';
.wrapper {
  position: relative;
}

.content {
  margin-bottom: 16px;
}

.contentWithLine {
  &::before {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 12px;
    width: 1px;
    background: repeating-linear-gradient(
      to bottom,
      $color-grey-light,
      $color-grey-light 4px,
      transparent 4px,
      transparent 8px
    );
  }
}

.wrapInitiated {
  margin-left: 32px;
  padding-bottom: 32px;
}

.container {
  height: auto;
  background: $color-white;
  border: 1px solid $color-dawn-pink;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 16px;
}

.stepIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #dadadb;
  text-align: center;
  align-items: center;
  margin-right: 8px;
  z-index: 10;
}

.approvedIcon {
  background-color: $color-master-success-500 !important;
}

.rejectedIcon {
  background-color: $color-master-error-500 !important;
}

.stepTitle {
  color: var(--color-black, #0b0b0c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.greyDot {
  width: 4px;
  height: 4px;
  background-color: var(--color-grey, #7b7b84);
  margin: 0 8px;
  border-radius: 50%;
}

.initiatedDate {
  color: var(--color-grey, #7b7b84);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.approverName {
  color: var(--$color-gunmetal, #2c303c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.info {
  margin-left: 32px;
  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }

  .reason {
    color: $color-master-error-500;
  }
}
