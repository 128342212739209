
.icon {
    margin-left: 20px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #D5D7DF;
    display: grid;
    align-content: center;
    justify-items: center;
  }
  .decrease {
    transform: rotate(180deg);
    margin-top: 1px;
  }