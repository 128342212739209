@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.description {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 18px;

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64697b;
  }

  &__viewRequest {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2e90fa;
    &:hover {
      cursor: pointer;
    }
    padding: auto;
  }
}

.drawerProfile {
  :global {
    .ant-drawer-header {
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;
    }
    button.ant-drawer-close {
      margin: 0;
      padding: 0;
    }

    .ant-drawer-body {
      padding: 24px 20px;
    }
  }
}

.tabProfile {
  :global {
    .ant-tabs-tab-btn {
      font-size: 14px;
      line-height: 20px;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      font-weight: 600;
      color: $color-primary;
    }

    .ant-tabs-ink-bar {
      background: #3b5ac4;
      border-radius: 4px 4px 0px 0px;
      height: 3px !important;
    }
  }
}

.profile {
  &__top {
    display: flex;
    align-items: center;
  }

  .name {
    max-width: calc(100% - 40px);
    @include text-ellipsis-in-line();

    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: $color-black;
    margin-right: 1rem;
  }
}

.action {
  display: flex;
  align-items: center;
  svg {
    margin-top: 3px;
  }
  .text {
    // margin-left: 13.5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 7px 0;
  }

  // &:hover {
  //   svg {
  //     fill: red;
  //     stroke: red;
  //   }
  //   .text {
  //     color: red;
  //   }
  // }
}
.delete {
  color: #f04438;
}
