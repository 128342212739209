@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  width: 100% !important;
  height: auto;
  background: $color-white;
  border: 1px solid $color-dawn-pink;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 16px;
}

.header {
  height: 20px;
  @include flex-between-center();

  .info {
    @include flex-between-center();

    .icon {
      width: fit-content;
      height: fit-content;
    }

    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      margin: 0 12px;
    }

    .approved {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-grey;
    }
  }

  .right {
    .size {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-grey;
    }
  }
}

.list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &__item {
    margin-right: 8px;
    margin-bottom: 20px;
  }
}
