@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  width: 100%;
  height: auto;
  background: $color-white;
  border: 1px solid $color-dawn-pink;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 16px;
}
.header {
  height: 20px;
  margin-bottom: 20px;
  @include flex-between-center();
  .info {
    @include flex-between-center();
    .icon {
      width: fit-content;
      height: fit-content;
    }
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      margin: 0 12px;
    }
  }
  .right {
    .size {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-grey;
    }
  }
}
.list {
  margin-top: 20px;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.content {
  &__header {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $color-black !important;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: $color-grey;
    text-transform: initial !important;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    margin: 0;
  }
}
