@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.loading {
  height: calc(100vh - 165px);
  @include flex-center();

  :global {
    .ant-spin-nested-loading {
      margin: 0px !important;
    }
  }
}

.edit__container {
  .body {
    padding: 0 36px;
    .header {
      display: flex;
      flex-direction: column;
      float: right;
      align-items: end;
      padding: 24px 24px;
      width: 100%;

      .steps {
        display: flex;
        align-items: center;

        .dot {
          height: 15px;
          width: 15px;
          background-color: $color-neutral-200;
          border-radius: 50%;
        }

        .line {
          height: 2px;
          width: 24px;
          background-color: $color-neutral-200;
        }

        .completedLine {
          background: linear-gradient(
            89.91deg,
            #e94ba7 -2.03%,
            #3b61c2 104.09%
          );
        }

        .current {
          box-shadow: 0px 0px 0px 3px #cedcff;
          background-color: $color-primary-600 !important;
        }

        .completed {
          background-color: $color-second-500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .step__name {
        margin-top: 12px;
      }

      .title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-neutral-500;
      }

      .description {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: $color-neutral-600;
      }
    }

    .actions {
      display: flex;
    }
  }

  .button {
    border: 0.5px solid #b5b6b7;
    color: #0b0b0c;
    height: 36px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &__back,
    &__cancel {
      background: $color-white;

      &:hover {
        background-color: $color-white;
        border: 0.5px solid $color-grey-light;
        color: $color-black;
      }

      &:focus {
        background-color: $color-white;
        border: 0.5px solid $color-grey-light;
        color: $color-black;
      }
    }

    &__next {
      background: $color-primary !important;
      color: $color-white !important;
      margin-left: 12px;
    }

    &--disabled {
      background: #dbdbde !important;
      border-radius: 24px;
      height: 36px;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #909193 !important;
      margin-left: 12px;
    }
  }

  .footer {
    margin-top: 36px;
    padding: 24px 48px;
    display: flex;
    margin-right: 0;
    justify-content: space-between;
    border-top: 1px solid $color-iron;
    box-shadow: 0px 0px 16px 8px rgba(130, 150, 218, 0.1),
      0px 2px 4px -2px rgba(12, 13, 18, 0.06);
  }
}

.modal {
  :global {
    .ant-modal-content {
      border-radius: 12px !important;

      .ant-modal-footer {
        padding: 20px !important;
        border-top: none;
      }

      .ant-modal-close {
        display: none !important;
      }
    }
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0b0b0c;
  }

  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7b7b84;
    margin-top: 8px;
  }
}
