@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.button {
  gap: 8px !important;
  background: #25397c !important;
  border-radius: 24px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: white !important;

  &__back {
    background-color: white !important;
    border: 0.5px solid #b5b6b7 !important;
    color: #0b0b0c !important;
  }

  &__cancel {
    background-color: transparent !important;
    color: #0b0b0c !important;
    box-shadow: none !important;
    border: 1px solid #D5D7DF !important;
  }

  &__disable {
    pointer-events: none !important;
    background-color: $border-color-default !important;
    border: none !important;
    color: #909193 !important;
  }
}
