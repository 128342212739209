@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.reject__popup {
  :global {
    .ant-modal-header {
      border-bottom: none;
      padding: 20px 20px 0 20px;
      .ant-modal-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
      }
    }
    .ant-modal-body {
      padding: 8px 20px 0px 20px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 20px;
    }
    .ant-modal-close {
      display: none !important;
    }

    .ant-form-item-label {
      padding: 20px 0 0 0 !important;
    }

  }

  .desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }

  .reason::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-grey;
  }
}
