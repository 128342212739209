@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.content {
    background: #ffffff;
    border: 1px solid #e3e6f0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 20px;
    margin-top: 28px;
  }

.user-inform {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #0c0d12;
    }
}

.name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
    margin: auto;
    white-space: nowrap;
}

.role {
    display: flex;
    align-items: center;
    gap: 8px;

    &__admin {
        color: #2e90fa;
    }

    .required {
        display: flex;
        gap: 5px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #d63a95;
        padding: 4px 8px;
        width: fit-content;
        height: 20px;
        background: #ffe7f5;
        border-radius: 24px;
        align-items: center;
    }
}

.passport_id {
    display: flex;
    align-items: center;
    &__icon {
        margin-right: 8px;
    }
}