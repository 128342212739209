.popup {
  :global {
    .ant-modal-footer {
      border-top: none !important;
    }

    .ant-modal-header {
      border-bottom: none !important;
      padding-bottom: 0px;
    }
    .ant-modal-title {
      font-size: 16px;
    }
  }
}
