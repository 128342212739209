@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  &__avatar {
    width: 28px;
    height: 28px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
    &--default {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $color-grey;
      color: $color-white;
      font-weight: 500;
      @include flex-center();
    }
  }
}

.avatar {
  width: 28px;
  height: 28px;
  border: 1px solid $color-grey-light;
  background-color: transparent;

  border-radius: 50%;
}
