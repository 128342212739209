@import 'styles/_variables.scss';
.container {
  height: auto;
  background: $color-white;
  border: 1px solid $color-dawn-pink;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 16px;
}
.header {
  display: flex;
  align-items: center;
  .icon {
    width: fit-content;
    height: fit-content;
  }
  .title {
    margin-left: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }
}