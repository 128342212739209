@import 'styles/_variables.scss';
.container {
  width: 368px;
  height: 398px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .icon {
    margin-top: 24px;
  }
  .content {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
  }
  .sub {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-white;
  }
  .link {
    margin-top: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $button-blue;
    cursor: pointer;
    text-decoration: underline;
  }
}
