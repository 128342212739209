@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.container {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  .search {
    width: 100%;
    display: flex;
    max-width: 316px;
  }
  .option {
    display: flex;
    justify-content: end;
    height: 36px;
    align-items: center;
    .attach {
      display: flex;
      height: 100%;
      gap: 12px;
      align-items: center;
      margin-right: 16px;
      .assigned {
        width: fit-content;
        font-weight: 400;
        font-size: 14px;
        color: #2c303c;
        :global {
          span.ant-badge.ant-badge-not-a-wrapper {
            margin-left: 12px;
          }
          sup.ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
            background-color: $button-pink;
            font-weight: 600;
            font-size: 10px;
            color: #ffffff;
            padding-top: 1px;
          }
        }
      }
      .checkbox {
        width: fit-content;
        :global {
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $button-pink;
            border-color: $button-pink;
          }
          .ant-checkbox-checked:after {
            border: 1px solid $button-pink;
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner,
          .ant-checkbox-wrapper:hover .ant-checkbox-inner,
          .ant-checkbox:hover .ant-checkbox-inner {
            border-color: $button-pink;
          }
        }
      }
    }
    .filter {
      width: auto;
      display: flex;
      justify-content: start;
      align-items: center;
      cursor: pointer;
      padding: 1rem;

      &__active {
        background: #ceddfb;
        border-radius: 24px;
        box-sizing: border-box;
      }
      .icon {
        height: fit-content;
        svg {
          margin-bottom: 4px;
        }
      }
      .title {
        margin-left: 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #64697b;
      }
    }
    .line {
      height: 100%;
      width: 1px;
      background: #dbdbde;
      margin: 0px 8px 0px 24px;
    }
    .download,
    .refresh {
      width: 36px;
      height: 36px;
      cursor: pointer;
      @include flex-center();
    }
    .review{
      margin-right: 20px;
      :global{
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $color-deep-cerise !important;
          box-shadow: 0px 2px 2px rgba(50, 68, 109, 0.06),
            0px 8px 16px rgba(2, 35, 96, 0.04);
          border-radius: 4px;
          border: none;
          &:hover,
          &:active {
            border: none !important;
          }
        }
        span.ant-checkbox {
          &:after,
          &:active {
            border: none !important;
          }
        }
        span.ant-checkbox {
          &:hover {
            span.ant-checkbox-inner {
              border-color: $color-deep-cerise!important;
              &::after {
                inset-inline-start: 25%;
              }
            }
          }
          span.ant-checkbox-inner {
            &::after {
              inset-inline-start: 25%;
            }
          }
        }
        .ant-checkbox+span{
          padding: 0px;
          padding-left: 12px;
        }
      }
    }
  }
}

.filter__modal {
  :global {
    .ant-popover-inner {
      border-radius: 8px;
      border: 0.5px solid #b5b6b7;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }

    .ant-popover-inner-content {
      padding: 20px 16px !important;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.filter__scroll {
  :global {
    .ant-popover-inner-content {
      padding: 20px 0 !important;
    }
  }
}

.customer {
  margin-left: 12px;
}
