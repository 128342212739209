@import 'styles/_variables.scss';

$top: 3px;

.rakkar-checkbox {
  span:not(.ant-checkbox) {
    font-size: 14px;
  }
  .ant-checkbox {
    .ant-checkbox-input {
      width: 20px;
      height: 20px;
      &::after {
        top: $top;
      }
    }
    .ant-checkbox-inner {
      border-radius: 4px;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(165, 178, 207, 1);
      box-shadow: 0px 2px 4px -2px rgba(37, 57, 124, 0.06);
      box-shadow: 0px 4px 8px -2px rgba(37, 57, 124, 0.1);
    }
  }
  .ant-checkbox,
  &:hover {
    .ant-checkbox-inner {
      border-radius: 4px;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $color-primary-600;
      border-color: $color-primary-600;
      border-radius: 4px;
    }
    &:hover {
      &::after {
        top: $top;
      }
    }
  }
}

.rakkar-checkbox .ant-checkbox .ant-checkbox-inner,
.rakkar-checkbox:hover .ant-checkbox-inner,
.rakkar-checkbox:hover .ant-checkbox-checked::after {
  top: $top;
}
